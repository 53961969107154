import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { getSelectedTileId } from "../../selectors/tilesSelectors";
import {
  getIsAddingTile,
  getIsEditingTile,
  getIsSideBarVisible,
} from "../../selectors/uiSelectors";
import "./Sidebar.css";
import TileAddMenu from "../TileAddMenu/TileAddMenu";
import TileEditMenu from "../TileEditMenu/TileEditMenu";
import SelectedTileInfo from "../SelectedTileInfo/SelectedTileInfo";
import SelectedViewInfo from "../SelectedViewInfo/SelectedViewInfo";
import ViewSelection from "../ViewSelection/ViewSelection";
import { getSelectedViewId } from "../../selectors/viewsSelectors";
import cooperantsLogo from "../../img/COOPERANTS_Logo.png";
import { AccountInfo } from "../AccountInfo/AccountInfo";
import { BiArrowToRight } from "react-icons/bi";
import { setIsSideBarVisible } from "../../slices/uiSlice";

const Sidebar = () => {
  const selectedViewId = useTypedSelector(getSelectedViewId);
  const selectedTileId = useTypedSelector(getSelectedTileId);
  const isEditingTile = useTypedSelector(getIsEditingTile);
  const isAddingTile = useTypedSelector(getIsAddingTile);
  const isSideBarVisible = useTypedSelector(getIsSideBarVisible);

  const dispatch = useTypedDispatch();

  const toggleSideBar = () => {
    dispatch(setIsSideBarVisible(!isSideBarVisible));
  };

  return (
    <div className={isSideBarVisible ? "sidebar" : "sidebar-minimized"}>
      {isSideBarVisible ? (
        <>
          <div className="sidebar-section">
            <img src={cooperantsLogo} className="logo" />
          </div>
          <div className="sidebar-section">
            <AccountInfo />
          </div>
          <div className="sidebar-section">
            <ViewSelection />
          </div>
          <div className="sidebar-section">
            <SelectedViewInfo isAddingTile={isAddingTile} />
          </div>
          {isAddingTile && (
            <div className="sidebar-section">
              <TileAddMenu />
            </div>
          )}
          {selectedViewId && (
            <div className="sidebar-section">
              <SelectedTileInfo selectedTileId={selectedTileId} />
            </div>
          )}
          {selectedTileId && isEditingTile && (
            <div className="sidebar-section">
              <TileEditMenu />
            </div>
          )}
        </>
      ) : (
        <BiArrowToRight
          className="minimize-arrow"
          size={35}
          onClick={() => toggleSideBar()}
        ></BiArrowToRight>
      )}
    </div>
  );
};

export default Sidebar;
