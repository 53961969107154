import { useEffect, useState } from "react";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import {
  getLoadedDatasetIds,
  getDatasetTypesByIds,
  getTileEditDatasets,
} from "../../selectors/datasetsSelectors";
import {
  // getTileDatasetPaths,
  getTileIsEdited,
  getTilePlotType,
  getTileTitle,
} from "../../selectors/tileSelectors";
import { getSelectedTileId } from "../../selectors/tilesSelectors";
import { getSelectedViewEdited } from "../../selectors/viewsSelectors";
import {
  setSelectedTileTitle,
  addEditedTileId,
  setSelectedTilePlotType,
  setSelectedTileDatasets,
} from "../../slices/tilesSlice";
import { setSelectedViewEdited } from "../../slices/viewsSlice";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { fetchDatasetByPath } from "../../apiCalls";
import {
  addDatasets,
  resetTileEditDatasets,
  toggleTileEditDataset,
} from "../../slices/datasetsSlice";
import {
  // availableDataSources,
  compatibilityDataToPlot,
  plotTypes,
  toastTypes,
} from "../../constants";
import {
  setIsSelectingDataSet,
  toggleIsEditingTile,
} from "../../slices/uiSlice";
import "./TileEditMenu.css";
import { BiCheck, BiX, BiError } from "react-icons/bi";
import DatasetSelection from "../DatasetSelection/DatasetSelection";
import popToastWithMessage from "../../utils/toast";
import { DatasetPath } from "../../interfaces/application/redux/Tile";

const TileEditMenu = () => {
  // Selected tile info
  const selectedTileId = useTypedSelector(getSelectedTileId);
  const selectedTileEdited = useTypedSelector(getTileIsEdited(selectedTileId));
  const selectedTileTitle = useTypedSelector(getTileTitle(selectedTileId));
  const selectedTilePlotType = useTypedSelector(
    getTilePlotType(selectedTileId),
  );

  // Local state
  const [tileEditTitle, setTileEditTitle] = useState("");
  const [tileEditPlotType, setTileEditPlotType] =
    useState(selectedTilePlotType);

  const [incompatibilityEncountered, setIncompatibilityEncountered] =
    useState(false);

  // Other redux state
  const selectedViewEdited = useTypedSelector(getSelectedViewEdited);
  const datasetTypesById = useTypedSelector(getDatasetTypesByIds);
  const tileEditDatasets = useTypedSelector(getTileEditDatasets);
  const loadedDatasetIds = useTypedSelector(getLoadedDatasetIds);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (tileEditPlotType && tileEditDatasets.length > 0) {
      checkDataAndPlotTypeCompatibility();
    } else {
      setIncompatibilityEncountered(false);
    }
  }, [tileEditDatasets, tileEditPlotType]);

  const checkDataAndPlotTypeCompatibility = () => {
    tileEditDatasets.forEach((datasetPath) => {
      const datasetType = datasetTypesById[datasetPath.dataSetId];
      if (
        datasetType &&
        !compatibilityDataToPlot[datasetType].includes(tileEditPlotType)
      ) {
        popToastWithMessage(
          `Plot type <${tileEditPlotType}> not compatible to dataset type <${datasetType}>!`,
          toastTypes.ERROR,
        );
        setIncompatibilityEncountered(true);
      } else {
        setIncompatibilityEncountered(false);
      }
    });
  };

  const setTileAndViewEdited = () => {
    if (!selectedViewEdited) {
      dispatch(setSelectedViewEdited(true));
    }
    if (!selectedTileEdited) {
      dispatch(addEditedTileId(selectedTileId));
    }
  };

  const resetEditTileMenuState = () => {
    setTileEditTitle("");
    setTileEditPlotType("");
    dispatch(resetTileEditDatasets());
    dispatch(toggleIsEditingTile());
    dispatch(setIsSelectingDataSet(false));
  };

  const onConfirmEditButtonClick = async () => {
    if (tileEditTitle || tileEditPlotType || tileEditDatasets.length > 0) {
      if (tileEditDatasets.length > 0) {
        Promise.all(
          tileEditDatasets.map(async (datasetPath) => {
            if (!loadedDatasetIds.includes(datasetPath.dataSetId)) {
              const dataset = await fetchDatasetByPath(datasetPath);
              dispatch(addDatasets([dataset]));
            }
          }),
        ).then(() => {
          dispatch(setSelectedTileDatasets(tileEditDatasets));
        });
      }
      if (tileEditTitle) {
        dispatch(setSelectedTileTitle(tileEditTitle));
      }
      if (tileEditPlotType) {
        dispatch(setSelectedTilePlotType(tileEditPlotType));
      }
      resetEditTileMenuState();
      setTileAndViewEdited();

      popToastWithMessage("Successfully edited the tile.");
    }
  };

  return (
    <div className="tile-edit-menu">
      <table style={{ width: "100%" }}>
        <tbody>
          <>
            <tr>
              <th className="tile-edit-table-section" colSpan={2}>
                <div className="tile-edit-table-section-content">
                  <div>Edit Tile</div>
                  <div>
                    {incompatibilityEncountered ? (
                      <BiError
                        className="tile-edit-table-section-content-symbols"
                        title="Incompatible plot type / dataset"
                      />
                    ) : (
                      <BiCheck
                        className="tile-edit-table-section-content-symbols"
                        title="Confirm"
                        onClick={onConfirmEditButtonClick}
                      />
                    )}
                    <BiX
                      className="tile-edit-table-section-content-symbols"
                      title="Cancel"
                      onClick={resetEditTileMenuState}
                    />
                  </div>
                </div>
              </th>
            </tr>
            <tr>
              <th className="tile-edit-table-row-key">Enter Title:</th>
              <td className="tile-edit-table-row-value">
                <input
                  key={selectedTileTitle ? selectedTileTitle : ""}
                  defaultValue={selectedTileTitle}
                  type="text"
                  onChange={(e) => setTileEditTitle(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <th className="tile-edit-table-row-key">Plot Type</th>
              <td className="tile-edit-table-row-value">
                <DropdownMenu
                  onDropdownItemClick={(plotType) =>
                    setTileEditPlotType(plotType)
                  }
                  items={Object.values(plotTypes)}
                  title={
                    tileEditPlotType ? tileEditPlotType : selectedTilePlotType
                  }
                  error={""}
                />
              </td>
            </tr>
          </>
        </tbody>
      </table>
      <DatasetSelection
        onDatasetToggle={(dataset: DatasetPath) => {
          dispatch(toggleTileEditDataset(dataset));
          // onToggleTileEditDataset(dataset);
        }}
        allSelectedDatasets={tileEditDatasets}
      />
    </div>
  );
};

export default TileEditMenu;
