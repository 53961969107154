import "./DashboardTile.css";

import {
  getIsSelectedTile,
  getTilePlotType,
  getTileIsEdited,
  getTilePosition,
  getTileTitle,
  getTileDatasetPaths,
} from "../../selectors/tileSelectors";
import { getSelectedViewId } from "../../selectors/viewsSelectors";

import {
  setSelectedTileId,
  reorderTiles,
  removeUnchangedTilesFromEditedList,
} from "../../slices/tilesSlice";

import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { itemTypes } from "../../constants";
import { formatDataset } from "../../utils/dataConversion";
import { getPlot } from "../../utils/plotGeneration";

import { useDrag, useDrop } from "react-dnd";
import { useEffect, useRef } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import {
  removeFromViewsEdited,
  setSelectedViewEdited,
  updateSelectedViewEdited,
} from "../../slices/viewsSlice";
import { getEditedTilesIds, getTiles } from "../../selectors/tilesSelectors";
import { getDatasets } from "../../selectors/datasetsSelectors";
import { DraggedTileItem } from "../../interfaces/application/draggedTileItem";

const DashboardTile = ({ id }: { id: string }) => {
  const dispatch = useTypedDispatch();
  const plotType = useTypedSelector(getTilePlotType(id));
  const title = useTypedSelector(getTileTitle(id));
  const position = useTypedSelector(getTilePosition(id));
  const isSelectedTile = useTypedSelector(getIsSelectedTile(id));
  const isEdited = useTypedSelector(getTileIsEdited(id));
  const datasetPaths = useTypedSelector(getTileDatasetPaths(id));
  const datasets = useTypedSelector(getDatasets);
  const tileDatasets = datasetPaths.map((datasetPath) => {
    return datasets[datasetPath.dataSetId];
  });
  const datasetContent = formatDataset(tileDatasets, plotType);
  const selectedViewId: string = useTypedSelector(getSelectedViewId);
  const tiles = useTypedSelector(getTiles);
  const editedTileIds = useTypedSelector(getEditedTilesIds);

  const onTitleClick = (id: string) => dispatch(setSelectedTileId(id));

  // DRAG AND DROP FUNCTIONALITY

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DraggedTileItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: itemTypes.TILE,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    // This method runs when another tile is being hover over THIS tile.
    hover(item: DraggedTileItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragId = item.id; // Tile being dragged
      const hoverId = id; // Tile being hovered over

      // Don't replace items with themselves
      if (dragId === hoverId) {
        return;
      }

      // Determine bounding rectangle (w.r.t. viewport) of THIS tile
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // mouseX - left edge x
      const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

      // Only perform the move when the mouse has crossed half of the items width.

      const hoverHalfWidth =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

      const dragPostion = item.position;
      const hoverPosition = position;

      // When dragging to the right, only move when the cursor is on the right 50%.
      if (dragPostion < hoverPosition && hoverClientX < hoverHalfWidth) {
        return;
      }

      // When dragging to the left, only move when the cursor is on the left 50%.
      if (dragPostion > hoverPosition && hoverClientX > hoverHalfWidth) {
        return;
      }

      // Time to actually perform the reordering.
      dispatch(reorderTiles([dragId, hoverId, selectedViewId]));

      // Remove tiles from edited list that are unchanged
      dispatch(removeUnchangedTilesFromEditedList(selectedViewId));
    },
  });

  const [{ isDragging }, dragRef] = useDrag({
    type: itemTypes.TILE,
    item: () => {
      return {
        id: id,
        title: title,
        position: position,
      };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  dragRef(drop(ref));

  // TODO: This is extremely confusing, needs consideration.
  useEffect(() => {
    // whenever there is change in editedTileIds check if tile has been added/removed
    let viewHasChanges = false;
    editedTileIds.map((tileId) => {
      if (tiles[tileId].viewId === selectedViewId) {
        viewHasChanges = true;
        return;
      }
    });
    if (viewHasChanges) {
      // if some tiles of current view are still in tiles edited set view as edited
      dispatch(setSelectedViewEdited(true));
    } else {
      // if tile is removed that translates that some of the position changes are brought back
      dispatch(setSelectedViewEdited(true));
      // tile is brought back and view has no more changes remove current view from views edited list
      dispatch(removeFromViewsEdited(selectedViewId));
      // set according to whether its in editedList or not
      dispatch(updateSelectedViewEdited());
    }
    // update the selected view edited flag either to set or unset depending if current view has any tile updated
    // set according to whether its in editedList or not
    dispatch(updateSelectedViewEdited());
  }, [editedTileIds]);

  return (
    <div
      className="dashboard-tile"
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      data-handler-id={handlerId}
    >
      <div
        className={
          isSelectedTile
            ? "dashboard-tile-title-selected"
            : "dashboard-tile-title"
        }
        onClick={() => onTitleClick(id)}
      >
        {isEdited ? "(edited) " + title : title}
      </div>
      <div className="dashboard-tile-content">
        {getPlot(plotType, datasetContent)}
      </div>
    </div>
  );
};

export default DashboardTile;
