import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getSelectedViewId } from "../selectors/viewsSelectors";
import { Tiles } from "../interfaces/application/redux/Tile";

export const getTiles = (state: RootState) => state.tiles.tiles;
export const getTilesCopy = (state: RootState) => state.tiles.tilesCopy;
export const getSelectedTileId = (state: RootState) =>
  state.tiles.selectedTileId;
export const getDeletedTilesIds = (state: RootState) =>
  state.tiles.deletedTilesIds;
export const getEditedTilesIds = (state: RootState) =>
  state.tiles.editedTilesIds;

export const getTilesIds = (state: RootState) => Object.keys(state.tiles.tiles);

export const getTilesForView = (viewId: string) =>
  createSelector(getTiles, (tiles) => {
    const filteredTiles: Tiles = {};
    for (const id in tiles) {
      if (tiles[id].viewId === viewId) {
        filteredTiles[id] = tiles[id];
      }
    }
    return filteredTiles;
  });

/**
 * Returns a list of tileIds for selected view, sorted by
 * their "position" property.
 */
export const getSortedTilesIdsForSelectedView = createSelector(
  getTiles,
  getSelectedViewId,
  (tiles, selectedViewId) => {
    const sortedTilesIds: string[] = [];
    const currentTabTiles: Tiles = {};
    //filter only tiles that are in selected view
    for (const id in tiles) {
      if (tiles[id].viewId === selectedViewId) {
        currentTabTiles[id] = tiles[id];
      }
    }
    for (let n = 0; n < Object.keys(currentTabTiles).length; n++) {
      const id = Object.keys(currentTabTiles).find(
        (id) => currentTabTiles[id].position === n,
      );
      if (id) {
        sortedTilesIds.push(id);
      } else {
        console.log(
          `### ERROR: Position ${n} could not be mapped to any tile id!`,
        );
      }
    }
    return sortedTilesIds;
  },
);

/**
 * Returns a list of tileIds for selected view,
 * sorted by their "position" property,
 * which are not marked for deletion.
 */
export const getDisplayTilesIdsForSelectedView = createSelector(
  getSortedTilesIdsForSelectedView,
  getDeletedTilesIds,
  (sortedTilesIds, deletedTilesIds) =>
    sortedTilesIds.filter((id) => {
      return !deletedTilesIds.includes(id);
    }),
);

export const getNumTiles = (state: RootState) => {
  // return number of tiles in selected view
  const selectedViewId = getSelectedViewId(state);
  let numOfTiles = 0;
  for (const i in state.tiles.tiles) {
    if (state.tiles.tiles[i].viewId === selectedViewId) {
      numOfTiles++;
    }
  }
  return numOfTiles;
};
