import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  fromDtoDataset,
  Datasets,
} from "../interfaces/application/redux/Dataset";
import { DatasetPath } from "../interfaces/application/redux/Tile";
import { DtoTreeNode } from "../interfaces/application/dto/dtoTreeNode";
import { DtoDataset } from "../interfaces/application/dto/dtoDataset";

interface DatasetsState {
  datasets: Datasets;
  tileAddDatasets: DatasetPath[];
  tileEditDatasets: DatasetPath[];
  rootNodes: { [id: string]: DtoTreeNode };
}

const initialState: DatasetsState = {
  datasets: {},
  tileAddDatasets: [],
  tileEditDatasets: [],
  rootNodes: {},
};

export const datasetsSlice = createSlice({
  name: "datasets",
  initialState,
  reducers: {
    resetDatasetsState: (state) => {
      state.datasets = {};
      state.tileAddDatasets = [];
      state.tileEditDatasets = [];
      state.rootNodes = {};
    },
    addDatasets: (state, action: PayloadAction<DtoDataset[]>) => {
      const datasetsById = action.payload.reduce(
        (red: Datasets, element: DtoDataset) => {
          return { ...red, [element.id]: fromDtoDataset(element) };
        },
        {},
      );
      state.datasets = { ...state.datasets, ...datasetsById };
    },
    toggleTileAddDataset: (state, action: PayloadAction<DatasetPath>) => {
      const datasetPath = action.payload;
      const currentState = state.tileAddDatasets;
      const idx = currentState.findIndex((d) => {
        return (
          d.dataSetId === datasetPath.dataSetId &&
          d.dataSourceId === datasetPath.dataSourceId
        );
      });
      if (idx === -1) {
        state.tileAddDatasets = [...currentState, datasetPath];
      } else {
        state.tileAddDatasets = [
          ...currentState.slice(0, idx),
          ...currentState.slice(idx + 1),
        ];
      }
    },
    resetTileAddDatasets: (state) => {
      state.tileAddDatasets = [];
    },
    toggleTileEditDataset: (state, action: PayloadAction<DatasetPath>) => {
      const datasetPath = action.payload;
      const currentState = state.tileEditDatasets;
      const idx = currentState.findIndex((d) => {
        return (
          d.dataSetId === datasetPath.dataSetId &&
          d.dataSourceId === datasetPath.dataSourceId
        );
      });
      if (idx === -1) {
        state.tileEditDatasets = [...currentState, datasetPath];
      } else {
        state.tileEditDatasets = [
          ...currentState.slice(0, idx),
          ...currentState.slice(idx + 1),
        ];
      }
    },
    resetTileEditDatasets: (state) => {
      state.tileEditDatasets = [];
    },
    setTileEditDatasets: (state, action: PayloadAction<DatasetPath[]>) => {
      state.tileEditDatasets = action.payload;
    },
    addRootNode: (
      state,
      action: PayloadAction<{ dataSourceId: string; rootNode: DtoTreeNode }>,
    ) => {
      const rootNode = action.payload.rootNode;
      const dataSourceId = action.payload.dataSourceId;
      state.rootNodes = { ...state.rootNodes, [dataSourceId]: rootNode };
    },
  },
});

export const {
  resetDatasetsState,
  addDatasets,
  toggleTileAddDataset,
  resetTileAddDatasets,
  toggleTileEditDataset,
  resetTileEditDatasets,
  setTileEditDatasets,
  addRootNode,
} = datasetsSlice.actions;

export default datasetsSlice.reducer;
