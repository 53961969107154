import { DatasetPath } from "../../interfaces/application/redux/Tile";
import { TreeNodeContent } from "../../interfaces/application/treeNodeContent";
import "./TreeStructureSection.css";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
// import { AiFillFileText } from "react-icons/ai";

interface Params {
  sectionTitle: string;
  sectionId: string;
  isDropDownOpen: boolean;
  sectionContents: TreeNodeContent[];
  onDropdownToggle: (option: string, nodeId: string) => void;
  onDatasetToggle: (dataset: DatasetPath) => void;
  selectedDatasets: DatasetPath[];
  selectedDataSourceId: string;
}
const TreeStructureSection: React.FC<Params> = ({
  sectionTitle,
  sectionId,
  isDropDownOpen,
  sectionContents,
  onDropdownToggle,
  onDatasetToggle,
  selectedDatasets,
  selectedDataSourceId,
}) => {
  const selectedDatasetIds = selectedDatasets.map((dataset) => {
    return dataset.dataSetId;
  });

  const calculateIsSectionSelected = () => {
    let sectionIsSelected = true;
    const sectionDatasetIds = sectionContents.map(
      (datasetItem) => datasetItem.id,
    );
    sectionDatasetIds.map((id) => {
      if (selectedDatasetIds.indexOf(id) === -1) {
        sectionIsSelected = false;
      }
    });
    return sectionIsSelected;
  };

  const onSectionToggle = () => {
    const sectionIsSelected = calculateIsSectionSelected();
    const sectionDatasetPaths: DatasetPath[] = sectionContents.map(
      (datasetItem) => {
        return {
          dataSetId: datasetItem.id,
          dataSourceId: selectedDataSourceId,
        };
      },
    );
    sectionDatasetPaths.map((datasetPath) => {
      if (
        selectedDatasetIds.indexOf(datasetPath.dataSetId) !== -1 &&
        sectionIsSelected
      ) {
        // un-select contained datasets if currently selected
        onDatasetToggle(datasetPath);
      } else if (
        selectedDatasetIds.indexOf(datasetPath.dataSetId) === -1 &&
        !sectionIsSelected
      ) {
        // select contained datasets if currently un-selected
        onDatasetToggle(datasetPath);
      }
    });
  };

  return (
    <div className="ts-section">
      <div className="ts-container-content">
        <div className="ts-container-title">
          <div>
            <input
              type="checkbox"
              checked={calculateIsSectionSelected()}
              onChange={() => onSectionToggle()}
            />
            {sectionTitle + "  ( " + sectionContents.length + "  )"}
          </div>
        </div>
        <div>
          {isDropDownOpen ? (
            <BiChevronUp
              className="ts-container-icon"
              size={25}
              onClick={() => onDropdownToggle(sectionTitle, sectionId)}
            />
          ) : (
            <BiChevronDown
              className="ts-container-icon"
              size={25}
              onClick={() => onDropdownToggle(sectionTitle, sectionId)}
            />
          )}
        </div>
      </div>
      <div className="ts-container-items">
        {isDropDownOpen &&
          sectionContents.map((datasetItem, index) => {
            const datasetPath: DatasetPath = {
              dataSetId: datasetItem.id,
              dataSourceId: selectedDataSourceId,
            };
            const isSelected =
              selectedDatasetIds.indexOf(datasetItem.id) !== -1;
            return (
              <div
                key={index}
                className={
                  isSelected
                    ? "ts-container-item-selected"
                    : "ts-container-item"
                }
              >
                <div className="ts-container-item-title">
                  {datasetItem.name}
                </div>
                <div>{}</div>
                <div>
                  <input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => {
                      onDatasetToggle(datasetPath);
                    }}
                  />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default TreeStructureSection;
