import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { getIsEditingTile } from "../../selectors/uiSelectors";
import { toggleIsEditingTile } from "../../slices/uiSlice";
import { addDeletedTileId, setSelectedTileId } from "../../slices/tilesSlice";
import {
  getTileDatasetPaths,
  getTilePlotType,
  getTileTitle,
} from "../../selectors/tileSelectors";
import { setSelectedViewEdited } from "../../slices/viewsSlice";
import "./SelectedTileInfo.css";
import { getDatasetTitlesForPaths } from "../../selectors/datasetSelectors";
import { BiPencil, BiSolidTrash } from "react-icons/bi";

const SelectedTileInfo = ({ selectedTileId }: { selectedTileId: string }) => {
  const dispatch = useTypedDispatch();

  const isEditingTile = useTypedSelector(getIsEditingTile);
  const selectedTileTitle = useTypedSelector(getTileTitle(selectedTileId));
  const selectedTilePlotType = useTypedSelector(
    getTilePlotType(selectedTileId),
  );
  const selectedTileDatasetPaths = useTypedSelector(
    getTileDatasetPaths(selectedTileId),
  );
  const selectedTileDatasetTitles = useTypedSelector(
    getDatasetTitlesForPaths(selectedTileDatasetPaths),
  );

  const onEditButtonClick = () => {
    dispatch(toggleIsEditingTile());
  };
  const onRemoveButtonClick = () => {
    if (isEditingTile) {
      dispatch(toggleIsEditingTile());
    }
    dispatch(setSelectedViewEdited(true));
    dispatch(addDeletedTileId(selectedTileId));
    dispatch(setSelectedTileId(""));
  };

  return (
    <div className="selected-tile-info">
      {selectedTileId ? (
        <table style={{ width: "100%" }}>
          <tbody>
            <>
              <tr>
                <th className="selected-tile-table-section" colSpan={2}>
                  <div className="selected-tile-table-section-content">
                    <div>Selected Tile</div>
                    <div>
                      <BiPencil
                        className="selected-tile-table-section-content-symbols"
                        title="Edit Tile"
                        onClick={onEditButtonClick}
                      />
                      <BiSolidTrash
                        className="selected-tile-table-section-content-symbols"
                        title="Remove Tile"
                        onClick={onRemoveButtonClick}
                      />
                    </div>
                  </div>
                </th>
              </tr>
              <tr>
                <th className="selected-tile-table-row-key">Title</th>
                <td className="selected-tile-table-row-value">
                  {selectedTileTitle}
                </td>
              </tr>
              <tr>
                <th className="selected-tile-table-row-key">Plot Type</th>
                <td className="selected-tile-table-row-value">
                  {selectedTilePlotType}
                </td>
              </tr>
              <tr>
                <th className="selected-tile-table-row-key">Data Sets</th>
                <td className="selected-tile-table-row-value">
                  {selectedTileDatasetTitles
                    ? selectedTileDatasetTitles.map((title) => {
                        return <div>{title}</div>;
                      })
                    : "Loading Dataset Titles"}
                </td>
              </tr>
            </>
          </tbody>
        </table>
      ) : (
        <>
          <p>No tile selected.</p>
          <p>Click on a tiles' title to select it.</p>
        </>
      )}
    </div>
  );
};

export default SelectedTileInfo;
