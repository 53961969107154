import "./Toast.css";

const Toast = () => {
  return (
    <>
      <div id="toast">TOAST</div>
    </>
  );
};

export default Toast;
