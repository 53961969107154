import {
  Chart as ChartJS,
  Colors,
  Tooltip,
  ArcElement,
  Legend,
  ChartData,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Point,
  BarElement,
} from "chart.js";
import { Doughnut, Line, Scatter, Bar, Pie } from "react-chartjs-2";
import DataTable from "../components/DataTable/DataTable";
import { plotTypes } from "../constants";
import { ChartJSContent } from "../interfaces/external/chartjs";
import { TabularData } from "../interfaces/application/tabularData";

const defaultStyle: React.CSSProperties = {
  position: "relative",
  height: "30vh",
  width: "25vw",
};

const applyChartJSOptions = (dataSet: ChartJSContent) => {
  // FUTURE TICKET:
  // ED-Client-040: Enable Visualization Configuration via Tile
  // Here, visualisation options will be applied.
  return dataSet;
};

export const getPlot = (
  plotType: string,
  dataset: ChartJSContent | TabularData,
) => {
  let datasetContent;
  if (plotType !== plotTypes.TABULAR) {
    datasetContent = applyChartJSOptions(dataset as ChartJSContent);
  }
  ChartJS.register(Colors);
  switch (plotType) {
    case plotTypes.TABULAR:
      return <DataTable data={dataset as TabularData} />;
    case plotTypes.DOUGHNUT:
      ChartJS.register(ArcElement, Tooltip, Legend);
      return (
        <Doughnut
          style={defaultStyle}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "left",
                align: "center",
                fullSize: false,
              },
              title: {
                display: true,
                position: "top",
                text: "(HARDCODED) Mass Distribution of equipment and summaries in : AOCS",
              },
            },
          }}
          data={datasetContent as ChartData<"doughnut", number[], unknown>}
        />
      );
    case plotTypes.LINE:
      ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
      );
      return (
        <Line
          style={defaultStyle}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: "Chart.js Line Chart",
              },
            },
          }}
          data={
            datasetContent as ChartData<
              "line",
              (number | Point | null)[],
              unknown
            >
          }
        />
      );
    case plotTypes.SCATTER:
      ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);
      return (
        <Scatter
          style={defaultStyle}
          options={{
            responsive: true,
            maintainAspectRatio: false,
          }}
          data={
            datasetContent as ChartData<
              "scatter",
              (number | Point | null)[],
              unknown
            >
          }
        />
      );
    case plotTypes.BAR:
      ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
      );
      return (
        <Bar
          style={defaultStyle}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: "bottom",
              },
              title: {
                display: true,
                position: "bottom",
                text: "(HARDCODED) Mass Distribution of equipment and summaries in : AOCS",
              },
            },
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          }}
          data={datasetContent as ChartData<"bar", [], unknown>}
        />
      );
    case plotTypes.PIE:
      ChartJS.register(ArcElement, Tooltip, Legend);
      return (
        <Pie
          style={defaultStyle}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true, // Hide the legend.
                position: "left",
              },
              title: {
                display: true,
                position: "bottom",
                text: "(HARDCODED) Mass Distribution of equipment and summaries in : AOCS",
              },
            },
          }}
          data={datasetContent as ChartData<"pie", number[], unknown>}
        />
      );

    default:
      return <div>unknown plot type: {plotType}</div>;
  }
};
