import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fromDtoView, View, Views } from "../interfaces/application/redux/View";
import { DtoView } from "../interfaces/application/dto/dtoView";

interface ViewsState {
  views: Views;
  selectedViewId: string;
  selectedViewEdited: boolean;
  viewsEdited: string[];
}

const initialState: ViewsState = {
  views: {},
  selectedViewId: "",
  selectedViewEdited: false,
  viewsEdited: [],
};

export const viewsSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    resetViewsState: (state) => {
      state.views = {};
      state.viewsEdited = [];
      state.selectedViewId = "";
      state.selectedViewEdited = false;
    },
    setViews: (
      state,
      action: PayloadAction<{ views: DtoView[]; contentLoaded: boolean }>,
    ) => {
      const views: Views = action.payload.views.reduce(
        (red: Views, element: DtoView) => {
          return {
            ...red,
            [element.id]: fromDtoView(element, action.payload.contentLoaded),
          };
        },
        {},
      );
      state.views = views;
    },
    addView: (state, action: PayloadAction<DtoView>) => {
      const view: View = fromDtoView(action.payload, false);
      state.views = { ...state.views, [view.id]: view };
    },
    removeView: (state, action: PayloadAction<string>) => {
      delete state.views[action.payload];
    },
    setSelectedViewId: (state, action: PayloadAction<string>) => {
      state.selectedViewId = action.payload;
    },
    /**
     * Update the selectedViewEdited flag as well as the viewsEdited array.
     */
    setSelectedViewEdited: (state, action: PayloadAction<boolean>) => {
      const selectedViewEdited = action.payload;
      state.selectedViewEdited = selectedViewEdited;
      // Update id array of all edited views.
      if (state.viewsEdited.includes(state.selectedViewId)) {
        if (!selectedViewEdited) {
          state.viewsEdited = state.viewsEdited.filter(
            (view) => view !== state.selectedViewId,
          );
        }
      } else {
        if (selectedViewEdited) {
          state.viewsEdited.push(state.selectedViewId);
        }
      }
    },
    updateSelectedViewEdited: (state) => {
      state.selectedViewEdited = state.viewsEdited.includes(
        state.selectedViewId,
      );
    },
    removeFromViewsEdited: (state, action: PayloadAction<string>) => {
      state.viewsEdited.splice(state.viewsEdited.indexOf(action.payload), 1);
    },
    closeView: (state, action: PayloadAction<string>) => {
      state.views[action.payload].opened = false;
    },
    openView: (state, action: PayloadAction<string>) => {
      state.views[action.payload].opened = true;
    },
    setViewContentLoaded: (
      state,
      action: PayloadAction<{ id: string; contentLoaded: boolean }>,
    ) => {
      state.views[action.payload.id].contentLoaded =
        action.payload.contentLoaded;
    },
  },
});

export const {
  resetViewsState,
  setViews,
  addView,
  removeView,
  setSelectedViewId,
  setSelectedViewEdited,
  updateSelectedViewEdited,
  removeFromViewsEdited,
  closeView,
  openView,
  setViewContentLoaded,
} = viewsSlice.actions;

export default viewsSlice.reducer;
