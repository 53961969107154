import { DeleteViewRequest } from "./interfaces/application/http/deleteViewRequest";
import { DeleteViewResponse } from "./interfaces/application/http/deleteViewResponse";
import { GetViewsForAccountResponse } from "./interfaces/application/http/getViewsForAccountResponse";
import { GetDataSetResponse } from "./interfaces/application/http/getDataSetReponse";
import { GetDatasetRequest } from "./interfaces/application/http/getDataSetRequest";
import { GetTilesForViewRequest } from "./interfaces/application/http/getTilesForViewRequest";
import { GetTilesForViewResponse } from "./interfaces/application/http/getTilesForViewResponse";
import { GetTreeStructureRequest } from "./interfaces/application/http/getTreeStructureRequest";
import { GetTreeStructureResponse } from "./interfaces/application/http/getTreeStructureResponse";
import { LoginRequest } from "./interfaces/application/http/loginRequest";
import { LoginResponse } from "./interfaces/application/http/loginResponse";
import { PostViewRequest } from "./interfaces/application/http/postViewRequest";
import { PostViewResponse } from "./interfaces/application/http/postViewResponse";
import { RegistrationRequest } from "./interfaces/application/http/registrationRequest";
import { RegistrationResponse } from "./interfaces/application/http/registrationResponse";
import { UpdateViewRequest } from "./interfaces/application/http/updateViewRequest";
import { UpdateViewResponse } from "./interfaces/application/http/updateViewResponse";
import { GetViewsForAccountRequest } from "./interfaces/application/http/getViewsForAccountRequest";
import { UpdateTilesForViewRequest } from "./interfaces/application/http/updateTilesForViewRequest";
import { UpdateTilesForViewResponse } from "./interfaces/application/http/updateTilesForViewResponse";

/* eslint-disable no-console*/

export const getTilesByViewId = async (
  request: GetTilesForViewRequest,
): Promise<GetTilesForViewResponse> => {
  return fetch(`/tile/${request.viewId}`)
    .then((res) => res.json())
    .then((jsonRes: GetTilesForViewResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const getDataset = async (
  request: GetDatasetRequest,
): Promise<GetDataSetResponse> => {
  return fetch(
    `/data-set/${request.dataSetId}/data-source/${request.dataSourceId}`,
  )
    .then((res) => res.json())
    .then((jsonRes: GetDataSetResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const getTreeStructure = async (
  request: GetTreeStructureRequest,
): Promise<GetTreeStructureResponse> => {
  return fetch(`/data-source/${request.dataSourceId}/tree-structure`)
    .then((res) => res.json())
    .then((jsonRes: GetTreeStructureResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const getViewsForAccount = async (
  request: GetViewsForAccountRequest,
): Promise<GetViewsForAccountResponse> => {
  return fetch(`/view/views/${request.accountId}`)
    .then((res) => res.json())
    .then((jsonRes: GetViewsForAccountResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const postView = async (request: PostViewRequest) => {
  return fetch(`/view`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((jsonRes: PostViewResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const patchTilesForView = async (request: UpdateTilesForViewRequest) => {
  return fetch(`/tile/${request.viewId}`, {
    method: "PATCH",
    body: JSON.stringify({ updatedTiles: request.updatedTiles }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((jsonRes: UpdateTilesForViewResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const patchView = async (request: UpdateViewRequest) => {
  return fetch(`/view/${request.updatedView.id}`, {
    method: "PATCH",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((jsonRes: UpdateViewResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const deleteView = async (request: DeleteViewRequest) => {
  return fetch(`/view/${request.viewId}`, { method: "DELETE" })
    .then((res) => res.json())
    .then((jsonRes: DeleteViewResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const postLogin = async (
  request: LoginRequest,
): Promise<LoginResponse> => {
  return fetch(`/account/login`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((jsonRes: LoginResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};

export const postAccount = async (
  request: RegistrationRequest,
): Promise<RegistrationResponse> => {
  return fetch(`/account/register`, {
    method: "POST",
    body: JSON.stringify(request),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((res) => res.json())
    .then((jsonRes: RegistrationResponse) => {
      if (jsonRes.error) {
        console.log(jsonRes.error.message);
      }
      return jsonRes;
    });
};
