import LoaderImage from "../utils/images/loader.svg";
import "./utils.css";
export const Loader = () => {
  return (
    <div>
      <div className="loader-container">
        <img src={LoaderImage} className="loader-image"></img>
        <div>Applying dataset</div>
      </div>
    </div>
  );
};
export default Loader;
