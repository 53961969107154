import { availableDataSources, plotTypes } from "../constants";
import { Dataset } from "../interfaces/application/redux/Dataset";
import { TabularData } from "../interfaces/application/tabularData";
import {
  BarChartDataPoint,
  BarChartDataSet,
  ChartJSContent,
  DoughnutOrPieOrLineChartDataSet,
  ScatterChartDataSet,
} from "../interfaces/external/chartjs";
import { VirSatMassObject } from "../interfaces/external/virsat";

// Generate and populate basic data structure according to dataset type and Tile plot type
export const formatDataset = (dtoDatasets: Dataset[], plotType: string) => {
  switch (plotType) {
    case plotTypes.TABULAR: {
      const result: TabularData = {
        tableDescription: "Dummy-Description",
        tableTitle: "Dummy-Title",
        sections: [],
      };
      dtoDatasets.forEach((dtoDataset) => {
        switch (dtoDataset.dataSourceId) {
          case availableDataSources.SAMPLE: {
            const content = dtoDataset.content as TabularData;
            result.sections.push(...content.sections);
            break;
          }
          case availableDataSources.VIRSAT: {
            const content = virsatToTabular(dtoDataset);
            // TODO: This is just to see how it looks if a section has several objects.
            // Will be adjusted when dataset levels are taken into account.
            if (result.sections[0]) {
              result.sections[0].section_data.push(
                ...content.sections[0].section_data,
              );
            } else {
              result.sections.push(...content.sections);
            }
            break;
          }
        }
      });
      return result as TabularData;
    }
    case plotTypes.BAR: {
      let resultDatasets: BarChartDataSet[] = [];
      let resultLabels: string[] = [];
      const massData: BarChartDataPoint[] = [];
      const marginData: BarChartDataPoint[] = [];
      dtoDatasets.forEach((dtoDataset) => {
        switch (dtoDataset.dataSourceId) {
          case availableDataSources.SAMPLE: {
            const content = dtoDataset.content as ChartJSContent;
            const datasets = content.datasets as BarChartDataSet[];
            const labels = content.labels as string[];
            resultDatasets = [...resultDatasets, ...datasets];
            resultLabels = [...resultLabels, ...labels];
            break;
          }
          case availableDataSources.VIRSAT: {
            const content = virsatToBarChart(dtoDataset);
            const datasets = content.datasets as BarChartDataSet[];
            const labels = content.labels as string[];
            massData.push(datasets[0].data[0]);
            marginData.push(datasets[1].data[0]);
            resultDatasets = [
              { label: "Mass", data: massData },
              { label: "Margin", data: marginData },
            ];
            resultLabels = [...resultLabels, ...labels];
            break;
          }
        }
      });
      return {
        datasets: resultDatasets,
        labels: resultLabels,
      } as ChartJSContent;
    }
    case plotTypes.DOUGHNUT:
    case plotTypes.PIE:
    case plotTypes.LINE: {
      let resultDatasets: DoughnutOrPieOrLineChartDataSet[] = [];
      let resultLabels: string[] = [];
      const massWithMarginData: number[] = [];
      dtoDatasets.forEach((dtoDataset) => {
        switch (dtoDataset.dataSourceId) {
          case availableDataSources.SAMPLE: {
            const content = dtoDataset.content as ChartJSContent;
            const datasets =
              content.datasets as DoughnutOrPieOrLineChartDataSet[];
            const labels = content.labels ? (content.labels as string[]) : [];
            resultDatasets = [...resultDatasets, ...datasets];
            resultLabels = [...resultLabels, ...labels];
            break;
          }
          case availableDataSources.VIRSAT: {
            const content = virsatToPieOrDoughnutOrLine(dtoDataset);
            const dataset = content
              .datasets[0] as DoughnutOrPieOrLineChartDataSet;
            const mass = dataset.data[0];
            const margin = dataset.data[1];
            massWithMarginData.push(mass, margin);
            resultDatasets = [
              { label: "value (kg)", data: massWithMarginData },
            ];
            resultLabels = [
              ...resultLabels,
              dataset.label + " (mass)",
              dataset.label + " (margin)",
            ];
            break;
          }
        }
      });
      return {
        datasets: resultDatasets,
        labels: resultLabels,
      } as ChartJSContent;
    }
    case plotTypes.SCATTER: {
      let resultDatasets: ScatterChartDataSet[] = [];
      dtoDatasets.forEach((dtoDataset) => {
        switch (dtoDataset.dataSourceId) {
          case availableDataSources.SAMPLE: {
            const content = dtoDataset.content as ChartJSContent;
            const datasets = content.datasets as ScatterChartDataSet[];
            resultDatasets = [...resultDatasets, ...datasets];
            break;
          }
          case availableDataSources.VIRSAT: {
            // Not implemented
            break;
          }
        }
      });
      return {
        datasets: resultDatasets,
      } as ChartJSContent;
    }
    default:
      console.log("### ERROR: Unknown plot type: ", plotType);
  }
  console.log("### ERROR: Could not convert data.");
  return {
    datasets: [],
    labels: [],
  } as ChartJSContent;
};

// If single mass object: display mass and margin.
// If more than one object, display mass of each object
const virsatToPieOrDoughnutOrLine = (dataset: Dataset) => {
  const content: VirSatMassObject[] = Object.values(dataset.content);
  let data: number[] = [];
  data = [content[0].massBean.value, content[0].massMarginBean.value];

  const resultContent: ChartJSContent = {
    labels: ["mass", "massMargin"],
    datasets: [
      {
        label: dataset.title,
        data: data,
      },
    ],
  };
  return resultContent;
};

const virsatToBarChart = (dataset: Dataset) => {
  const content: VirSatMassObject[] = Object.values(dataset.content);
  const resultContent: ChartJSContent = {
    labels: [dataset.title],
    datasets: [
      {
        label: "mass",
        data: content.map((c) => {
          return [0, c.massBean.value] as BarChartDataPoint;
        }),
      },
      {
        label: "margin",
        data: content.map((c) => {
          return [0, c.massMarginBean.value] as BarChartDataPoint;
        }),
      },
    ],
  };
  return resultContent;
};

const virsatToTabular = (dataset: Dataset) => {
  const result: TabularData = {
    tableTitle: dataset.title,
    tableDescription: "Sample description of VirSat data",
    sections: [
      {
        section_title: dataset.title,
        section_data: Object.values(dataset.content).map((c) => {
          return {
            object_name: dataset.title,
            margin: c.marginBean.value,
            mass: c.massBean.value,
            mass_margin: c.massMarginBean.value,
            mass_with_margin: c.massWithMarginBean.value,
          };
        }),
      },
    ],
  };
  return result;
};
