import { Modal } from "react-bootstrap";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { getClosedViews } from "../../selectors/viewsSelectors";
import { useState } from "react";
import { setSelectedTileId } from "../../slices/tilesSlice";
import { setShowViewSelectionModal } from "../../slices/uiSlice";
import { openView, setSelectedViewId } from "../../slices/viewsSlice";
import { getShowViewSelectionModal } from "../../selectors/uiSelectors";
import "./ViewSelectionModal.css";

export const ViewSelectionModal = () => {
  const dispatch = useTypedDispatch();
  const closedViews = useTypedSelector(getClosedViews);
  const showViewSelectionModal = useTypedSelector(getShowViewSelectionModal);
  const [selectedViewIdInModal, setSelectedViewIdInModal] = useState("");

  // NOTE: Recent test implementation allows to open tab even
  // if there is currently a change in a different tab.
  /**
   * Add tab to local storage, fetch tiles and unloaded datasets for
   * selected view, update tabs in redux state, and switch to opened tab.
   */
  const onOpenTabClick = async (viewId: string) => {
    dispatch(openView(viewId));
    // Set the newly opened view as selected view.
    dispatch(setSelectedViewId(viewId));

    // Reset local component state.
    setSelectedViewIdInModal("");
    dispatch(setShowViewSelectionModal(false));
    dispatch(setSelectedTileId(""));
  };

  const closeModal = () => {
    dispatch(setShowViewSelectionModal(false));
    setSelectedViewIdInModal("");
  };

  return (
    <Modal show={showViewSelectionModal}>
      <div className="views-container">
        <div className="views-container-header">
          <h5>All Views</h5>
          <h5
            className="views-container-header-cross"
            onClick={() => closeModal()}
          >
            {" "}
            X{" "}
          </h5>
        </div>
        <div className="scrollbar">
          {closedViews.map((view, index) => {
            return (
              <div
                key={index}
                className={
                  selectedViewIdInModal === view.id
                    ? "views-element view-element-selected"
                    : "views-element"
                }
                onClick={() => setSelectedViewIdInModal(view.id)}
              >
                {view.title}
              </div>
            );
          })}
        </div>
      </div>
      <div className="open-tab-container">
        <div
          className={
            selectedViewIdInModal === ""
              ? "open-tab-button"
              : "open-tab-button open-tab-button-selected"
          }
          onClick={() => onOpenTabClick(selectedViewIdInModal)}
        >
          OPEN TAB
        </div>
      </div>
    </Modal>
  );
};
