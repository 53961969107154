import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "../interfaces/application/http/loginResponse";

interface AccountState {
  accountId: string;
  email: string;
  loggedIn: boolean;
}

const initialState: AccountState = {
  accountId: "",
  email: "",
  loggedIn: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    resetAccountState: (state) => {
      state.accountId = "";
      state.email = "";
      state.loggedIn = false;
    },
    setAccount: (state, action: PayloadAction<LoginResponse>) => {
      state.email = action.payload.email;
      state.accountId = action.payload.accountId;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
  },
});

export const { resetAccountState, setAccount, setLoggedIn } =
  accountSlice.actions;
export default accountSlice.reducer;
