import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const getViews = (state: RootState) => state.views.views;
export const getSelectedViewId = (state: RootState) =>
  state.views.selectedViewId;
export const getSelectedViewEdited = (state: RootState) =>
  state.views.selectedViewEdited;
export const getEditedViewIds = (state: RootState) => state.views.viewsEdited;
export const getOpenViews = createSelector(getViews, (views) => {
  return Object.values(views).filter((view) => view.opened);
});
export const getClosedViews = createSelector(getViews, (views) => {
  return Object.values(views).filter((view) => !view.opened);
});
