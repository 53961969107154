import {
  deleteView,
  getViewsForAccount,
  getDataset,
  getTilesByViewId,
  getTreeStructure,
  postLogin,
  postView,
  patchView,
  postAccount,
  patchTilesForView,
} from "./api";
import { toastTypes } from "./constants";
import { DtoDataset } from "./interfaces/application/dto/dtoDataset";
import { DtoTile } from "./interfaces/application/dto/dtoTile";
import { DtoTreeNode } from "./interfaces/application/dto/dtoTreeNode";
import { DtoView } from "./interfaces/application/dto/dtoView";
import { GetTreeStructureRequest } from "./interfaces/application/http/getTreeStructureRequest";
import { GetViewsForAccountRequest } from "./interfaces/application/http/getViewsForAccountRequest";
import { LoginRequest } from "./interfaces/application/http/loginRequest";
import { PostViewResponse } from "./interfaces/application/http/postViewResponse";
import { RegistrationRequest } from "./interfaces/application/http/registrationRequest";
import { UpdateTilesForViewResponse } from "./interfaces/application/http/updateTilesForViewResponse";
import { UpdateViewResponse } from "./interfaces/application/http/updateViewResponse";
import { DatasetPath } from "./interfaces/application/redux/Tile";
import popToastWithMessage from "./utils/toast";

export const fetchTilesForView = async (viewId: string): Promise<DtoTile[]> => {
  const response = await getTilesByViewId({ viewId: viewId });
  if (response.error) {
    popToastWithMessage(`Error fetching tiles`, toastTypes.ERROR);
  }
  return response.tiles;
};

export const fetchDatasetByPath = async (
  datasetPath: DatasetPath,
): Promise<DtoDataset> => {
  const response = await getDataset(datasetPath);
  if (response.error) {
    popToastWithMessage(`Error fetching dataset`, toastTypes.ERROR);
  }
  return response.dataset;
};

export const fetchTreeStructure = async (
  request: GetTreeStructureRequest,
): Promise<DtoTreeNode> => {
  const response = await getTreeStructure(request);
  if (response.error) {
    popToastWithMessage(`Error fetching tree structure`, toastTypes.ERROR);
  }
  return response.rootNode;
};

export const fetchViewsForAccount = async (
  request: GetViewsForAccountRequest,
): Promise<DtoView[]> => {
  const response = await getViewsForAccount(request);
  if (response.error) {
    popToastWithMessage(`Error fetching views`, toastTypes.ERROR);
  }
  return response.views;
};

/**
 * Add view and subsequently add corresponding tiles if provided.
 */
export const createView = async (
  viewTitle: string,
  tileDtos: DtoTile[],
  accountId: string,
): Promise<PostViewResponse> => {
  return await postView({
    viewTitle: viewTitle,
    tileDtos: tileDtos,
    accountId: accountId,
  });
};

/**
 * Replace tiles of given view on DB according to current application state.
 */
export const updateTilesForView = async (
  viewId: string,
  updatedTiles: DtoTile[],
): Promise<UpdateTilesForViewResponse> => {
  return await patchTilesForView({
    viewId: viewId,
    updatedTiles: updatedTiles,
  });
};

export const updateView = async (
  updatedView: DtoView,
): Promise<UpdateViewResponse> => {
  return await patchView({
    updatedView: updatedView,
  });
};

// TODO: Find solution for such terrible naming issues.
// Hopefully introducing async thunks will get rid off most of them.
/**
 * Delete all tiles belonging to viewId, then delete view.
 */
export const deleteView_ = async (viewId: string) => {
  return await deleteView({ viewId: viewId });
};

// TODO: rename "postLogin".
export const login = async (accountCredentials: LoginRequest) => {
  return await postLogin(accountCredentials);
};

export const register = async (accountCredentials: RegistrationRequest) => {
  return await postAccount(accountCredentials);
};
