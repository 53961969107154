import { Modal } from "react-bootstrap";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { setShowLoginModal, setShowRegisterModal } from "../../slices/uiSlice";
import { getShowRegisterModal } from "../../selectors/uiSelectors";
import {
  BiError,
  BiInfoCircle,
  BiKey,
  BiLogIn,
  BiUser,
  BiUserPlus,
} from "react-icons/bi";
import "./LoginRegistrationModal.css";
import { useState } from "react";
import { register } from "../../apiCalls";
import { validateEmail, validatePassword } from "../../utils/validation";
import popToastWithMessage from "../../utils/toast";
import { RegistrationRequest } from "../../interfaces/application/http/registrationRequest";
import { RegistrationResponse } from "../../interfaces/application/http/registrationResponse";

export const RegistrationModal = () => {
  const dispatch = useTypedDispatch();
  const showRegisterModal = useTypedSelector(getShowRegisterModal);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [repeatedPasswordError, setRepeatedPasswordError] = useState("");
  const [registrationError, setRegistrationError] = useState("");
  const [registrationInfo, setRegistrationInfo] = useState("");

  const resetInputState = () => {
    setEmail("");
    setPassword("");
    setRepeatedPassword("");
  };

  const resetErrorState = () => {
    setEmailError("");
    setPasswordError("");
    setRepeatedPasswordError("");
    setRegistrationError("");
  };

  const validateInput = () => {
    let inputIsValid = true;
    if (!validateEmail(email)) {
      setEmailError("Non-valid email address");
      inputIsValid = false;
    }
    if (!validatePassword(password)) {
      setPasswordError("Non-valid password");
      inputIsValid = false;
    }
    if (password && password !== repeatedPassword) {
      setRepeatedPasswordError("Entered passwords are not identical");
      inputIsValid = false;
    }
    return inputIsValid;
  };

  const onBackToLoginButtonClick = () => {
    dispatch(setShowRegisterModal(false));
    dispatch(setShowLoginModal(true));

    resetInputState();
    resetErrorState();
    setRegistrationInfo("");
  };

  const onRegisterButtonClick = async () => {
    resetErrorState();

    // Only proceed with valid input.
    if (!validateInput()) {
      return;
    }

    const registrationData: RegistrationRequest = {
      email: email,
      password: password,
    };

    // Send registration request.
    const registrationResult: RegistrationResponse = await register(
      registrationData,
    );

    if (registrationResult.error) {
      // If registration is not successful, show error message.
      setRegistrationError(registrationResult.error.message);
      return;
    } else {
      // If registration is successful, show info message.
      popToastWithMessage(`Registration Email was sent!`);
      setRegistrationInfo(`
      A confirmation email was sent to "${email}".
      Please follow the given instructions and you are ready to login.
      If you dont see any email, please check your spam folder and ensure the specified email address is correct.
      If you need assistance, please contact support.`);
    }

    resetInputState();
  };

  return (
    <Modal show={showRegisterModal}>
      <div className="login-container">
        <div className="login-header">
          <h5>SIGN UP</h5>
        </div>
        <div className="login-input">
          <div className={"input-element"}>
            <BiUser />
            <input
              type="text"
              placeholder={"Sign up with your email address"}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {emailError && (
            <div className="login-error">
              <BiError />
              {emailError}
            </div>
          )}
          <div className={"input-element"}>
            <BiKey />
            <input
              type="text"
              placeholder={"Choose a password"}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {passwordError && (
            <div className="login-error">
              <BiError />
              <>
                <div>{passwordError}</div>
                <div>
                  Password requirements:
                  <ul>
                    <li>At least one uppercase letter</li>
                    <li>At least one lowercase letter</li>
                    <li>At least one digit</li>
                    <li>At least one special symbol</li>
                    <li>More than 4 characters</li>
                  </ul>
                </div>
              </>
            </div>
          )}
          <div className={"input-element"}>
            <BiKey />
            <input
              type="text"
              placeholder={"Repeat Password"}
              onChange={(e) => setRepeatedPassword(e.target.value)}
            />
          </div>
          {repeatedPasswordError && (
            <div className="login-error">
              <BiError />
              {repeatedPasswordError}
            </div>
          )}
          {registrationError && (
            <div className="login-error">
              <BiError />
              {registrationError}
            </div>
          )}
          {registrationInfo && (
            <div className="login-info">
              <BiInfoCircle />
              {registrationInfo}
            </div>
          )}
        </div>
      </div>
      <div className="button-container">
        <div className="login-button" onClick={onRegisterButtonClick}>
          <BiUserPlus />
          Send Invitation Email
        </div>
        <div className="login-button" onClick={onBackToLoginButtonClick}>
          <BiLogIn />
          Back to Login
        </div>
      </div>
    </Modal>
  );
};
