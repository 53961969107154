export const itemTypes = {
  TILE: "tile",
};

export const plotTypes = {
  TABULAR: "tabular",
  LINE: "lineChart",
  SCATTER: "scatterChart",
  DOUGHNUT: "doughnut",
  BAR: "bar",
  PIE: "pie",
};

export const toastTypes = {
  INFO: "info",
  ERROR: "error",
};

export const datasetTypes = {
  TABULAR: "tabularData",
  LINE: "lineChartData",
  SCATTER: "scatterChartData",
  DOUGHNUT: "doughnutData",
  BAR: "BarChartData",
  PIE: "PIEChartData",
  VIRSAT: "virsat",
};

export const availableDataSources = {
  VIRSAT: "virsat",
  SAMPLE: "sample",
};

// This dummy implementation only ensures the app wont crash on an invalid selection.

export const compatibilityPlotToData = {
  [plotTypes.TABULAR]: [datasetTypes.TABULAR, datasetTypes.VIRSAT],
  [plotTypes.LINE]: [datasetTypes.LINE],
  [plotTypes.SCATTER]: [
    datasetTypes.SCATTER,
    datasetTypes.TABULAR,
    datasetTypes.LINE,
    datasetTypes.DOUGHNUT,
  ],
  [plotTypes.DOUGHNUT]: [
    datasetTypes.DOUGHNUT,
    datasetTypes.LINE,
    datasetTypes.PIE,
    datasetTypes.VIRSAT,
  ],
  [plotTypes.PIE]: [
    datasetTypes.PIE,
    datasetTypes.DOUGHNUT,
    datasetTypes.LINE,
    datasetTypes.VIRSAT,
  ],
  [plotTypes.BAR]: [datasetTypes.BAR, datasetTypes.VIRSAT],
};

export const compatibilityDataToPlot = {
  [datasetTypes.TABULAR]: [plotTypes.TABULAR],
  [datasetTypes.LINE]: [plotTypes.LINE],
  [datasetTypes.SCATTER]: [plotTypes.SCATTER],
  [datasetTypes.DOUGHNUT]: [plotTypes.DOUGHNUT, plotTypes.PIE],
  [datasetTypes.PIE]: [plotTypes.PIE, plotTypes.DOUGHNUT],
  [datasetTypes.BAR]: [plotTypes.BAR],
  [datasetTypes.VIRSAT]: [
    plotTypes.BAR,
    plotTypes.DOUGHNUT,
    plotTypes.PIE,
    plotTypes.TABULAR,
  ],
};
