import "./Dashboard.css";
import DashboardTile from "../DashboardTile/DashboardTile";
import { getDisplayTilesIdsForSelectedView } from "../../selectors/tilesSelectors";
import { TabBar } from "../TabBar/TabBar";
import { useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import {
  setSelectedViewId,
  setViewContentLoaded,
} from "../../slices/viewsSlice";
import { getIsLoadingUserData } from "../../selectors/uiSelectors";
import Loader from "../../utils/Loader";
import { fetchDatasetByPath, fetchTilesForView } from "../../apiCalls";
import { getLoadedDatasetIds } from "../../selectors/datasetsSelectors";
import { addDatasets } from "../../slices/datasetsSlice";
import { addTiles } from "../../slices/tilesSlice";
import { DatasetPath } from "../../interfaces/application/redux/Tile";
import { getLoggedIn } from "../../selectors/accountSelectors";
import { getOpenViews } from "../../selectors/viewsSelectors";
import { setIsLoadingUserData } from "../../slices/uiSlice";

/**
 * Displays grid of interactive tiles, organized into tabs.
 */
const Dashboard = () => {
  const dispatch = useTypedDispatch();

  const loggedIn = useTypedSelector(getLoggedIn);
  const openViews = useTypedSelector(getOpenViews);
  const displayTilesIds = useTypedSelector(getDisplayTilesIdsForSelectedView);
  const loadedDatasetIds = useTypedSelector(getLoadedDatasetIds);
  const isLoadingUserData = useTypedSelector(getIsLoadingUserData);

  useEffect(() => {
    if (!loggedIn) {
      dispatch(setSelectedViewId(""));
    } else {
      dispatch(setIsLoadingUserData(true));
      Promise.all(
        openViews.map((view) => {
          if (!view.contentLoaded) {
            fetchTilesAndDataSetsForView(view.id);
            dispatch(
              setViewContentLoaded({ id: view.id, contentLoaded: true }),
            );
          }
        }),
      );
      dispatch(setIsLoadingUserData(false));
    }
  }, [loggedIn, openViews]);

  /**
   * Fetch tiles and unfetched datasets for given viewId and add them to state.
   */
  const fetchTilesAndDataSetsForView = async (viewId: string) => {
    const tiles = await fetchTilesForView(viewId);
    const dataSetsToFetch: DatasetPath[] = [];
    tiles.forEach((tile) => {
      tile.datasetPaths.forEach((datasetPath) => {
        if (!loadedDatasetIds.includes(datasetPath.dataSetId)) {
          dataSetsToFetch.push(datasetPath);
        }
      });
    });
    if (dataSetsToFetch.length !== 0) {
      await Promise.all(
        dataSetsToFetch.map(async (datasetPath) => {
          const dataset = await fetchDatasetByPath(datasetPath);
          dispatch(addDatasets([dataset]));
        }),
      );
    }
    dispatch(addTiles(tiles));
  };

  return (
    <div className="dashboard">
      <TabBar />
      {isLoadingUserData ? (
        <Loader />
      ) : (
        <>
          <div className="dashboard-charts">
            {displayTilesIds.map((tileId: string) => {
              return <DashboardTile id={tileId} key={tileId} />;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
