import { BiLogOut, BiUser } from "react-icons/bi";
import { setShowLoginModal } from "../../slices/uiSlice";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import "./AccountInfo.css";
import { getAccountInfo } from "../../selectors/accountSelectors";
import { resetAccountState } from "../../slices/accountSlice";
import { resetViewsState } from "../../slices/viewsSlice";
import { resetTilesState } from "../../slices/tilesSlice";
import { resetDatasetsState } from "../../slices/datasetsSlice";
import popToastWithMessage from "../../utils/toast";
import { getEditedViewIds, getViews } from "../../selectors/viewsSelectors";
import { toastTypes } from "../../constants";
import { updateView } from "../../apiCalls";

export const AccountInfo = () => {
  const accountInfo = useTypedSelector(getAccountInfo);
  const editedViewIds = useTypedSelector(getEditedViewIds);
  const views = useTypedSelector(getViews);
  const dispatch = useTypedDispatch();

  const onLogoutButtonClick = async () => {
    // Check for unsaved changes.
    if (editedViewIds.length !== 0) {
      popToastWithMessage(
        "Please save or discard changes first",
        toastTypes.ERROR,
      );
      return;
    }

    // Update views in DB to save their current "opened" status.
    await Promise.all(
      Object.values(views).map((view) => {
        updateView(view);
      }),
    );

    // Clear redux state: account, views, tiles, datasets.
    dispatch(resetAccountState());
    dispatch(resetViewsState());
    dispatch(resetTilesState());
    dispatch(resetDatasetsState());

    // Return to login application state.
    dispatch(setShowLoginModal(true));
    popToastWithMessage("Logged out successfully!");
  };
  return (
    <div className="account-info">
      <div>
        <BiUser />
        {accountInfo.email}
      </div>
      <div className="logout-button" onClick={onLogoutButtonClick}>
        <BiLogOut />
        Logout
      </div>
    </div>
  );
};
