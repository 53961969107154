import Sidebar from "../Sidebar/Sidebar";
import Dashboard from "../Dashboard/Dashboard";
import Toast from "../Toast/Toast";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./DashboardWrapper.css";

const DashboardWrapper = () => {
  return (
    <div className="dashboard-wrapper">
      <Sidebar />
      <DndProvider debugMode={true} backend={HTML5Backend}>
        <Dashboard />
      </DndProvider>
      <Toast />
    </div>
  );
};

export default DashboardWrapper;
