import { useState } from "react";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import "./ViewSelection.css";

import { setSelectedViewId, addView, openView } from "../../slices/viewsSlice";
import {
  toggleIsCreatingView,
  setIsSideBarVisible,
} from "../../slices/uiSlice";
import {
  getIsCreatingView,
  getIsSideBarVisible,
} from "../../selectors/uiSelectors";
import { createView } from "../../apiCalls";
import { BiArrowToLeft } from "react-icons/bi";
import popToastWithMessage from "../../utils/toast";
import { toastTypes } from "../../constants";
import { setSelectedTileId } from "../../slices/tilesSlice";
import { getAccountInfo } from "../../selectors/accountSelectors";

const ViewSelection = () => {
  const dispatch = useTypedDispatch();

  const [viewCreateName, setViewCreateName] = useState("");

  const accountInfo = useTypedSelector(getAccountInfo);
  const isCreatingView = useTypedSelector(getIsCreatingView);
  const isSideBarVisible = useTypedSelector(getIsSideBarVisible);

  // VIEW CREATION

  const onViewCreateButtonClick = () => {
    dispatch(toggleIsCreatingView());
  };

  const resetViewCreateState = () => {
    setViewCreateName("");
  };

  const onCreateViewConfirm = async () => {
    if (!viewCreateName) {
      popToastWithMessage("View name can't be empty.", toastTypes.ERROR);
      return;
    }
    const postViewResponse = await createView(
      viewCreateName,
      [],
      accountInfo.id,
    );
    if (postViewResponse.error) {
      popToastWithMessage(`Error creating view`, toastTypes.ERROR);
      return;
    }
    const createdView = postViewResponse.addViewResult;
    dispatch(addView(createdView));

    dispatch(toggleIsCreatingView());
    resetViewCreateState();
    popToastWithMessage(`Successfully created view "${createdView.title}".`);

    // Open and select created view.
    dispatch(openView(createdView.id));
    dispatch(setSelectedViewId(createdView.id));
    dispatch(setSelectedTileId(""));
  };

  const onViewCreateCancel = () => {
    dispatch(toggleIsCreatingView());
    resetViewCreateState();
  };

  const toggleSideBar = () => {
    dispatch(setIsSideBarVisible(!isSideBarVisible));
  };

  return (
    <>
      {isSideBarVisible && (
        <>
          <div className="create-view-button-wrapper">
            <button
              className="create-view-button"
              onClick={onViewCreateButtonClick}
            >
              Create View
            </button>
            <BiArrowToLeft
              className="minimize-arrow"
              size={35}
              onClick={() => toggleSideBar()}
            ></BiArrowToLeft>
          </div>
          {isCreatingView && (
            <div className="create-view">
              <input
                className="create-view-item"
                type="text"
                placeholder={"Enter new view name"}
                onChange={(e) => setViewCreateName(e.target.value)}
              />
              <div className="menu-items-wrapper">
                <button
                  className="create-view-item"
                  onClick={onViewCreateCancel}
                >
                  CANCEL
                </button>
                <button
                  className="create-view-item"
                  onClick={onCreateViewConfirm}
                >
                  CONFIRM
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ViewSelection;
