import { useState } from "react";
import "./TreeStructure.css";
import TreeStructureSection from "../TreeStructureSection/TreeStructureSection";
import { DtoTreeNode } from "../../interfaces/application/dto/dtoTreeNode";
import { DatasetPath } from "../../interfaces/application/redux/Tile";
import { TreeNodeContent } from "../../interfaces/application/treeNodeContent";

const TreeStructure = ({
  rootNode,
  onDatasetToggle,
  selectedDataSourceId,
  selectedDatasets,
}: {
  rootNode: DtoTreeNode;
  onDatasetToggle: (dataset: DatasetPath) => void;
  selectedDataSourceId: string;
  selectedDatasets: DatasetPath[];
}) => {
  const initialDropdownOptions: { [name: string]: boolean } = rootNode?.children
    .map((childNode) => {
      return childNode.name;
    })
    .reduce((object, name) => ({ ...object, [name]: false }), {});

  const [dropdownOpen, setDropdownOpen] = useState(initialDropdownOptions);
  // const [searchText, setSearchText] = useState("");
  // const dataset = useTypedSelector(getTreeStructureDataset);

  const toggleDropdownOption = (name: string) => {
    const dropDownOption = dropdownOpen;
    dropDownOption[name] = !dropDownOption[name];
    setDropdownOpen({ ...dropDownOption });
  };

  // const onChangeSearchInput = (value: string) => {
  //   setSearchText(value);
  // };
  return (
    <div className="ts-container">
      <div className="ts-sub-container">
        {/* <div>FILTER</div>
        <div>
          <input
            placeholder="Search Dataset"
            onChange={(e) => onChangeSearchInput(e.target.value)}
            className="ts-search-filter"
          />
        </div> */}
        <div className="dataset-text">{rootNode.name}</div>
        {rootNode.children.map((sectionNode, index) => {
          const sectionContents: TreeNodeContent[] = sectionNode.children.map(
            (childNode) => {
              return {
                name: childNode.name,
                type: childNode.type,
                id: childNode.uuid,
              };
            },
          );
          return (
            <TreeStructureSection
              key={index}
              sectionTitle={sectionNode.name}
              sectionId={sectionNode.uuid}
              sectionContents={sectionContents}
              isDropDownOpen={dropdownOpen[sectionNode.name]}
              onDropdownToggle={(option: string) =>
                toggleDropdownOption(option)
              }
              onDatasetToggle={onDatasetToggle}
              selectedDatasets={selectedDatasets}
              selectedDataSourceId={selectedDataSourceId}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TreeStructure;
