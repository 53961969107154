import { toastTypes } from "../constants";

const popToastWithMessage = (message: string, toastType = "info") => {
  const toast = document.getElementById("toast");

  if (toast) {
    toast.textContent = message;

    switch (toastType) {
      case toastTypes.INFO:
        toast.className = "show_info";
        toast.style.backgroundColor = "#1f8a69";
        toast.style.color = "white";
        break;
      case toastTypes.ERROR:
        toast.className = "show_error";
        toast.style.backgroundColor = "#cf6679";
        toast.style.color = "black";
        toast.style.transform = "translate(0,0)";
        break;
    }
  }

  setTimeout(function () {
    if (toast) {
      toast.className = "";
    }
  }, 3000);
};

export default popToastWithMessage;
