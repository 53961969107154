// Actions
import {
  closeView,
  setSelectedViewId,
  updateSelectedViewEdited,
} from "../../slices/viewsSlice";

// Selectors
import {
  getSelectedViewId,
  getEditedViewIds,
  getOpenViews,
  getViews,
} from "../../selectors/viewsSelectors";

import "./TabBar.css";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { setSelectedTileId } from "../../slices/tilesSlice";
import {
  setIsAddingTile,
  setIsEditingTile,
  setIsSelectingDataSet,
  setShowViewSelectionModal,
} from "../../slices/uiSlice";
import { ViewSelectionModal } from "../Modals/ViewSelectionModal";
import popToastWithMessage from "../../utils/toast";
import { toastTypes } from "../../constants";

export const TabBar = () => {
  const dispatch = useTypedDispatch();
  const views = useTypedSelector(getViews);
  const openViews = useTypedSelector(getOpenViews);
  const selectedViewId = useTypedSelector(getSelectedViewId);
  const editedViewIds = useTypedSelector(getEditedViewIds);

  const onCloseViewClick = (viewId: string) => {
    // Check if view has unsaved changes.
    if (editedViewIds.includes(viewId)) {
      popToastWithMessage(
        "Please save or discard changes first",
        toastTypes.ERROR,
      );
      return;
    }
    dispatch(closeView(viewId));
    // If closing the currently selected view, select next/previous view if available.
    if (viewId === selectedViewId) {
      const indexOfView = openViews.indexOf(views[viewId]);
      if (openViews[indexOfView + 1]) {
        dispatch(setSelectedViewId(openViews[indexOfView + 1].id));
      } else if (openViews[indexOfView - 1]) {
        dispatch(setSelectedViewId(openViews[indexOfView - 1].id));
      } else {
        dispatch(setSelectedViewId(""));
      }
    }
  };

  const onTabClick = (viewId: string) => {
    // Reset sidebar when switching tabs
    if (viewId !== selectedViewId) {
      dispatch(setSelectedTileId(""));
      dispatch(setIsAddingTile(false));
      dispatch(setIsEditingTile(false));
      dispatch(setIsSelectingDataSet(false));
    }
    // set new tab as selected view
    dispatch(setSelectedViewId(viewId));
    // update selectedViewEdited flag also if current view is updated
    dispatch(updateSelectedViewEdited());
  };

  return (
    <div className="tabContainer">
      {openViews.map((view, index) => {
        return (
          <div
            className={view.id === selectedViewId ? "tab tab-selected" : "tab"}
            key={index}
          >
            <div onClick={() => onTabClick(view.id)} className="tabTitle">
              {view.title}
              {editedViewIds.includes(view.id) ? "*" : null}
            </div>
            <div
              className="tabCross"
              onClick={() => {
                onCloseViewClick(view.id);
              }}
            >
              x
            </div>
          </div>
        );
      })}
      <div
        className="add-view-button"
        onClick={() => {
          dispatch(setShowViewSelectionModal(true));
        }}
      >
        <span className="add-button-text">+</span>
      </div>
      <ViewSelectionModal />
    </div>
  );
};
