import { DtoTile } from "../dto/dtoTile";

export interface Tile {
  title: string;
  plotType: string;
  position: number;
  viewId: string;
  datasetPaths: DatasetPath[];
}

export interface DatasetPath {
  dataSetId: string;
  dataSourceId: string;
}

export interface Tiles {
  [id: string]: Tile;
}

export const tileFromDto = (dtoTile: DtoTile): Tile => {
  return {
    title: dtoTile.title,
    plotType: dtoTile.plotType,
    position: dtoTile.position,
    viewId: dtoTile.viewId,
    datasetPaths: dtoTile.datasetPaths,
  };
};

export const toDtoTile = (
  tile: Tile,
  tileId: string,
  viewId: string,
): DtoTile => {
  return {
    id: tileId,
    title: tile.title,
    plotType: tile.plotType,
    position: tile.position,
    viewId: viewId,
    datasetPaths: tile.datasetPaths,
  };
};
