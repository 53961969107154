import "./App.css";
import DashboardWrapper from "./components/DashboardWrapper/DashboardWrapper";
import { LoginModal } from "./components/Modals/LoginModal";
import { RegistrationModal } from "./components/Modals/RegistrationModal";

function App() {
  return (
    <div className="App">
      <DashboardWrapper />
      <LoginModal />
      <RegistrationModal />
    </div>
  );
}

export default App;
