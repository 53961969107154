import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  DatasetPath,
  Tile,
  tileFromDto,
  Tiles,
} from "../interfaces/application/redux/Tile";
import { DtoTile } from "../interfaces/application/dto/dtoTile";

interface TilesState {
  selectedTileId: string;
  deletedTilesIds: string[];
  editedTilesIds: string[];
  tiles: Tiles;
  tilesCopy: Tiles;
}

const initialState: TilesState = {
  selectedTileId: "",
  deletedTilesIds: [],
  editedTilesIds: [],
  tiles: {},
  tilesCopy: {},
};

export const tilesSlice = createSlice({
  name: "tiles",
  initialState,
  reducers: {
    resetTilesState: (state) => {
      state.tiles = {};
      state.tilesCopy = {};
      state.deletedTilesIds = [];
      state.editedTilesIds = [];
      state.selectedTileId = "";
    },
    // For each given tile, if tile in state with matching id exists,
    // replace it with given tile.
    replaceTiles: (state, action: PayloadAction<DtoTile[]>) => {
      const tilesById = action.payload.reduce(
        (red: Tiles, element: DtoTile) => {
          return { ...red, [element.id]: tileFromDto(element) };
        },
        {},
      );
      for (const idToReplace in tilesById) {
        for (const id in state.tiles) {
          if (idToReplace === id) {
            delete state.tiles[id];
            delete state.tilesCopy[id];
          }
        }
      }
      state.tiles = { ...state.tiles, ...tilesById };
      state.tilesCopy = { ...state.tilesCopy, ...tilesById };
    },
    updateTilesCopy: (state) => {
      state.tilesCopy = state.tiles;
    },
    addTiles: (state, action: PayloadAction<DtoTile[]>) => {
      const tilesById = action.payload.reduce(
        (red: Tiles, element: DtoTile) => {
          return { ...red, [element.id]: tileFromDto(element) };
        },
        {},
      );
      state.tiles = { ...state.tiles, ...tilesById };
      state.tilesCopy = { ...state.tilesCopy, ...tilesById };
    },
    addNewTile: (state, action: PayloadAction<Tile>) => {
      const id: string = uuidv4();
      state.tiles = { ...state.tiles, [id]: action.payload };
    },
    // REVIEW: why are deleted and edited treated differently?
    addDeletedTileId: (state, action: PayloadAction<string>) => {
      state.deletedTilesIds = [...state.deletedTilesIds, action.payload];
    },
    addEditedTileId: (state, action: PayloadAction<string>) => {
      state.editedTilesIds = Array.from(
        new Set([...state.editedTilesIds, action.payload]),
      );
    },
    resetModifiedTileIds: (state) => {
      state.deletedTilesIds = [];
      state.editedTilesIds = [];
    },
    removeEditedTileIds: (state, action: PayloadAction<string[]>) => {
      //only remove the tiles of current view after save
      const updateTiles = state.editedTilesIds.filter(
        (item) => !action.payload.includes(item),
      );
      state.editedTilesIds = updateTiles;
    },
    removeTile: (state, action: PayloadAction<string>) => {
      delete state.tiles[action.payload];
    },
    removeUnchangedTilesFromEditedList: (
      state,
      action: PayloadAction<string>,
    ) => {
      const selectedViewId = action.payload;
      for (const id in state.tiles) {
        // Only consider tiles in selected view.
        if (state.tiles[id].viewId === selectedViewId) {
          // Tile is unchanged.
          if (isTileSame(state.tiles[id], state.tilesCopy[id])) {
            const indexOfEditedTile = state.editedTilesIds.indexOf(id);
            // Tile is listed as edited.
            if (indexOfEditedTile !== -1) {
              // Remove tile from list of edited Tiles.
              state.editedTilesIds.splice(indexOfEditedTile, 1);
            }
          }
        }
      }
      const updatedList = Array.from(new Set([...state.editedTilesIds]));
      state.editedTilesIds = updatedList;
    },
    setSelectedTileId: (state, action: PayloadAction<string>) => {
      state.selectedTileId = action.payload;
    },
    setSelectedTileTitle: (state, action: PayloadAction<string>) => {
      state.tiles[state.selectedTileId].title = action.payload;
    },
    setSelectedTilePlotType: (state, action: PayloadAction<string>) => {
      state.tiles[state.selectedTileId].plotType = action.payload;
    },
    setSelectedTileDatasets: (state, action: PayloadAction<DatasetPath[]>) => {
      state.tiles[state.selectedTileId].datasetPaths = action.payload;
    },
    reorderTiles: (state, action: PayloadAction<string[]>) => {
      const dragId = action.payload[0]; // Tile being dragged
      const hoverId = action.payload[1]; // Tile being hovered over
      const selectedViewId = action.payload[2];
      const dragPosition = state.tiles[dragId].position;
      const hoverPosition = state.tiles[hoverId].position;

      const draggingForward = dragPosition < hoverPosition;
      for (const id in state.tiles) {
        // Only consider tiles in selected view.
        if (state.tiles[id].viewId === selectedViewId) {
          const position = state.tiles[id].position;
          if (draggingForward) {
            // The current tile is both of the following
            // 1. either being hovered over or left of the hovered-over tile
            // 2. right of the original position of tile being dragged
            if (position <= hoverPosition && position > dragPosition) {
              // Tile moves to the left and is thereby edited.
              state.tiles[id].position = position - 1;
              state.editedTilesIds = Array.from(
                new Set([...state.editedTilesIds, id]),
              );
            }
          } else {
            // dragging backward
            // The current tile is both of the following
            // 1. either being hovered over or right of the hovered-over tile
            // 2. left of the original position of tile being dragged
            if (position >= hoverPosition && position < dragPosition) {
              // Tile moves to the right and is thereby edited.
              state.tiles[id].position = position + 1;
              state.editedTilesIds = Array.from(
                new Set([...state.editedTilesIds, id]),
              );
            }
          }
        }
      }

      // Update dragged tiles' position.
      state.tiles[dragId].position = hoverPosition;

      // Dragged and hovered-over tiles are edited.
      state.editedTilesIds = Array.from(
        new Set([...state.editedTilesIds, dragId, hoverId]),
      );
    },
  },
});
const isTileSame = (tile1: Tile, tile2: Tile) => {
  if (
    tile1.plotType === tile2.plotType &&
    tile1.position === tile2.position &&
    tile1.title === tile2.title
  )
    return true;
  return false;
};
export const {
  resetTilesState,
  replaceTiles,
  updateTilesCopy,
  addTiles,
  addNewTile,
  addDeletedTileId,
  addEditedTileId,
  resetModifiedTileIds,
  removeTile,
  setSelectedTileId,
  setSelectedTileTitle,
  setSelectedTilePlotType,
  setSelectedTileDatasets,
  reorderTiles,
  removeEditedTileIds,
  removeUnchangedTilesFromEditedList,
} = tilesSlice.actions;

export default tilesSlice.reducer;
