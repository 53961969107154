import { DtoView } from "../dto/dtoView";

export interface View {
  id: string;
  title: string;
  accountId: string;
  opened: boolean;
  contentLoaded: boolean;
}

export interface Views {
  [id: string]: View;
}

export const fromDtoView = (dtoView: DtoView, contentLoaded: boolean): View => {
  return {
    id: dtoView.id,
    title: dtoView.title,
    accountId: dtoView.accountId,
    opened: dtoView.opened,
    contentLoaded: contentLoaded,
  };
};
