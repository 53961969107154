import { Modal } from "react-bootstrap";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import {
  setIsLoadingUserData,
  setShowLoginModal,
  setShowRegisterModal,
} from "../../slices/uiSlice";
import { getShowLoginModal } from "../../selectors/uiSelectors";
import {
  BiError,
  BiHide,
  BiKey,
  BiLogIn,
  BiShow,
  BiUser,
  BiUserPlus,
} from "react-icons/bi";
import "./LoginRegistrationModal.css";
import { useState } from "react";
import { fetchViewsForAccount, login } from "../../apiCalls";
import { validateEmail } from "../../utils/validation";
import { setAccount, setLoggedIn } from "../../slices/accountSlice";
import popToastWithMessage from "../../utils/toast";
import { LoginRequest } from "../../interfaces/application/http/loginRequest";
import { LoginResponse } from "../../interfaces/application/http/loginResponse";
import { setSelectedViewId, setViews } from "../../slices/viewsSlice";

export const LoginModal = () => {
  const dispatch = useTypedDispatch();
  const showLoginModal = useTypedSelector(getShowLoginModal);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  const resetInputState = () => {
    setEmail("");
    setPassword("");
  };

  const resetErrorState = () => {
    setEmailError("");
    setPasswordError("");
    setLoginError("");
  };

  const validateInput = () => {
    let inputIsValid = true;
    if (!email || !validateEmail(email)) {
      setEmailError("Non-valid email adress");
      inputIsValid = false;
    }
    if (!password) {
      setPasswordError("Missing password");
      inputIsValid = false;
    }
    return inputIsValid;
  };

  const onLoginButtonClick = async () => {
    resetErrorState();

    // Only proceed with valid input.
    if (!validateInput()) {
      return;
    }

    const accountCredentials: LoginRequest = {
      email: email,
      password: password,
    };

    // Send login request.
    const loginResult: LoginResponse = await login(accountCredentials);

    if (loginResult.error) {
      setLoginError(loginResult.error.message);
      return;
    }
    popToastWithMessage(`Login successful!`);

    // Set logged in Account.
    dispatch(setAccount(loginResult));
    dispatch(setLoggedIn(true));

    // Get Views for Account and set them in Redux.
    dispatch(setIsLoadingUserData(true));
    const views = await fetchViewsForAccount({
      accountId: loginResult.accountId,
    });
    dispatch(setViews({ views: views, contentLoaded: false }));
    dispatch(setIsLoadingUserData(false));

    // Initial open view selection.
    const openViews = Object.values(views).filter((dtoView) => dtoView.opened);
    if (openViews.length > 0) {
      dispatch(setSelectedViewId(openViews[0].id));
    }

    // Proceed to Dashboard.
    dispatch(setShowLoginModal(false));

    resetInputState();
  };

  const onRegisterButtonClick = () => {
    dispatch(setShowLoginModal(false));
    dispatch(setShowRegisterModal(true));

    resetInputState();
    resetErrorState();
  };

  return (
    <Modal show={showLoginModal}>
      <div className="login-container">
        <div className="login-header">
          <h5>LOGIN</h5>
        </div>
        <div className="login-input">
          <div className={"input-element"}>
            <BiUser />
            <input
              type="text"
              autoComplete="off"
              placeholder={"Email Address"}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailError && (
              <div className="login-error">
                <BiError />
                {emailError}
              </div>
            )}
          </div>
          <div className={"input-element"}>
            <BiKey />
            <input
              style={{ marginRight: "10px" }}
              type={passwordVisible ? "text" : "password"}
              autoComplete="off"
              placeholder={"Password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordVisible ? (
              <BiShow onClick={() => setPasswordVisible(false)} />
            ) : (
              <BiHide onClick={() => setPasswordVisible(true)} />
            )}
            {passwordError && (
              <div className="login-error">
                <BiError />
                <div>{passwordError}</div>
              </div>
            )}
          </div>
          {loginError && (
            <div className="login-error">
              <BiError />
              {loginError}
            </div>
          )}
        </div>
      </div>
      <div className="button-container">
        <div className="login-button" onClick={onRegisterButtonClick}>
          <BiUserPlus />
          Register
        </div>
        <div className="login-button" onClick={onLoginButtonClick}>
          <BiLogIn />
          Login
        </div>
      </div>
    </Modal>
  );
};
