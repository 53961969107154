import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

export const getDatasets = (state: RootState) => state.datasets.datasets;
export const getTileAddDatasets = (state: RootState) =>
  state.datasets.tileAddDatasets;
export const getTileEditDatasets = (state: RootState) =>
  state.datasets.tileEditDatasets;
export const getDatasetTitles = (state: RootState) =>
  Object.values(state.datasets.datasets).map((dataset) => dataset.title);
export const getRootNodes = (state: RootState) => state.datasets.rootNodes;

export const getLoadedDatasetIds = (state: RootState) =>
  Object.keys(state.datasets.datasets);

export const getDatasetTypesByIds = createSelector(
  getRootNodes,
  (rootNodes) => {
    const endResult: { [id: string]: string } = {};

    for (const treeNode of Object.values(rootNodes)) {
      const firstChildren = treeNode.children;
      for (const firstChild of firstChildren) {
        const secondChildren = firstChild.children;
        for (const secondChild of secondChildren) {
          endResult[secondChild.uuid] = secondChild.type;
        }
      }
    }
    return endResult;
  },
);
