import { useEffect, useState } from "react";
import { addNewTile } from "../../slices/tilesSlice";
import {
  toggleIsAddingTile,
  setIsSelectingDataSet,
} from "../../slices/uiSlice";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { setSelectedViewEdited } from "../../slices/viewsSlice";
import "./TileAddMenu.css";
import { getNumTiles } from "../../selectors/tilesSelectors";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { fetchDatasetByPath } from "../../apiCalls";
import {
  addDatasets,
  resetTileAddDatasets,
  toggleTileAddDataset,
} from "../../slices/datasetsSlice";
import {
  getLoadedDatasetIds,
  getDatasetTypesByIds,
  getTileAddDatasets,
} from "../../selectors/datasetsSelectors";
import {
  compatibilityDataToPlot,
  plotTypes,
  toastTypes,
} from "../../constants";
import { getSelectedViewId } from "../../selectors/viewsSelectors";
import { BiCheck, BiError, BiX } from "react-icons/bi";
import DatasetSelection from "../DatasetSelection/DatasetSelection";
import popToastWithMessage from "../../utils/toast";
import { DatasetPath } from "../../interfaces/application/redux/Tile";

const TileAddMenu = () => {
  const [tileAddTitle, setTileAddTitle] = useState("");
  const [tileAddPlotType, setTileAddPlotType] = useState("");
  const [incompatibilityEncountered, setIncompatibilityEncountered] =
    useState(false);

  const numTiles = useTypedSelector(getNumTiles);
  const datasetTypesById = useTypedSelector(getDatasetTypesByIds);
  const selectedViewId = useTypedSelector(getSelectedViewId);
  const tileAddDatasets = useTypedSelector(getTileAddDatasets);
  const loadedDatasetIds = useTypedSelector(getLoadedDatasetIds);
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (tileAddPlotType && tileAddDatasets.length > 0) {
      checkDataAndPlotTypeCompatibility();
    } else {
      setIncompatibilityEncountered(false);
    }
  }, [tileAddDatasets, tileAddPlotType]);

  const checkDataAndPlotTypeCompatibility = () => {
    tileAddDatasets.forEach((datasetPath) => {
      const datasetType = datasetTypesById[datasetPath.dataSetId];
      if (
        datasetType &&
        !compatibilityDataToPlot[datasetType].includes(tileAddPlotType)
      ) {
        popToastWithMessage(
          `Plot type <${tileAddPlotType}> not compatible to dataset type <${datasetType}>!`,
          toastTypes.ERROR,
        );
        setIncompatibilityEncountered(true);
      } else {
        setIncompatibilityEncountered(false);
      }
    });
  };

  const resetAddTileState = () => {
    setTileAddTitle("");
    setTileAddPlotType("");
    dispatch(resetTileAddDatasets());
    dispatch(toggleIsAddingTile());
    dispatch(setIsSelectingDataSet(false));
  };

  const onConfirmAddButtonClick = async () => {
    if (tileAddTitle && tileAddPlotType && tileAddDatasets.length > 0) {
      await Promise.all(
        tileAddDatasets.map(async (datasetPath) => {
          if (!loadedDatasetIds.includes(datasetPath.dataSetId)) {
            const dataset = await fetchDatasetByPath(datasetPath);
            dispatch(addDatasets([dataset]));
          }
        }),
      );
      dispatch(
        addNewTile({
          title: tileAddTitle,
          plotType: tileAddPlotType,
          position: numTiles,
          viewId: selectedViewId,
          datasetPaths: tileAddDatasets,
        }),
      );
      resetAddTileState();
      dispatch(setSelectedViewEdited(true));
    } else {
      popToastWithMessage(
        "Plot type, datasets and title are required.",
        toastTypes.ERROR,
      );
    }
  };

  return (
    <div className="tile-add-menu">
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th className="tile-add-table-section" colSpan={2}>
              <div className="tile-add-table-section-content">
                <div>Add Tile</div>
                <div>
                  {incompatibilityEncountered ? (
                    <BiError
                      className="tile-add-table-section-content-symbols"
                      title="Incompatible plot type / dataset"
                    />
                  ) : (
                    <BiCheck
                      className="tile-add-table-section-content-symbols"
                      title="Confirm"
                      onClick={onConfirmAddButtonClick}
                    />
                  )}
                  <BiX
                    className="tile-add-table-section-content-symbols"
                    title="Cancel"
                    onClick={resetAddTileState}
                  />
                </div>
              </div>
            </th>
          </tr>
          <tr>
            <th className="tile-add-table-row-key">Enter Title:</th>
            <td className="tile-add-table-row-value">
              <input
                type="text"
                onChange={(e) => setTileAddTitle(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <th className="tile-add-table-row-key">Plot Type</th>
            <td className="tile-add-table-row-value">
              <DropdownMenu
                onDropdownItemClick={(plotType) => setTileAddPlotType(plotType)}
                items={Object.values(plotTypes)}
                title={tileAddPlotType ? tileAddPlotType : "select"}
                error={""}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <DatasetSelection
        onDatasetToggle={(dataset: DatasetPath) => {
          dispatch(toggleTileAddDataset(dataset));
        }}
        allSelectedDatasets={tileAddDatasets}
      />
    </div>
  );
};

export default TileAddMenu;
