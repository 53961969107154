import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UiState {
  isEditingTile: boolean;
  isAddingTile: boolean;
  isCreatingView: boolean;
  isDraggingOverTileId: string;
  isSideBarVisible: boolean;
  isLoadingUserData: boolean;
  isSelectingDataSet: boolean;
  showViewSelectionModal: boolean;
  showLoginModal: boolean;
  showRegisterModal: boolean;
}

const initialState: UiState = {
  isEditingTile: false,
  isAddingTile: false,
  isCreatingView: false,
  isDraggingOverTileId: "",
  isSideBarVisible: true,
  isLoadingUserData: false,
  isSelectingDataSet: false,
  showViewSelectionModal: false,
  showLoginModal: true,
  showRegisterModal: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleIsEditingTile: (state) => {
      state.isEditingTile = !state.isEditingTile;
    },
    setIsEditingTile: (state, action: PayloadAction<boolean>) => {
      state.isEditingTile = action.payload;
    },
    toggleIsAddingTile: (state) => {
      state.isAddingTile = !state.isAddingTile;
    },
    setIsAddingTile: (state, action: PayloadAction<boolean>) => {
      state.isAddingTile = action.payload;
    },
    toggleIsCreatingView: (state) => {
      state.isCreatingView = !state.isCreatingView;
    },
    setIsDraggingOverTileId: (state, action: PayloadAction<string>) => {
      state.isDraggingOverTileId = action.payload;
    },
    setIsSideBarVisible: (state, action: PayloadAction<boolean>) => {
      state.isSideBarVisible = action.payload;
    },
    setIsLoadingUserData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUserData = action.payload;
    },
    setIsSelectingDataSet: (state, action: PayloadAction<boolean>) => {
      state.isSelectingDataSet = action.payload;
    },
    setShowViewSelectionModal: (state, action: PayloadAction<boolean>) => {
      state.showViewSelectionModal = action.payload;
    },
    setShowLoginModal: (state, action: PayloadAction<boolean>) => {
      state.showLoginModal = action.payload;
    },
    setShowRegisterModal: (state, action: PayloadAction<boolean>) => {
      state.showRegisterModal = action.payload;
    },
  },
});

export const {
  toggleIsEditingTile,
  setIsEditingTile,
  toggleIsAddingTile,
  setIsAddingTile,
  toggleIsCreatingView,
  setIsDraggingOverTileId,
  setIsSideBarVisible,
  setIsLoadingUserData,
  setIsSelectingDataSet,
  setShowViewSelectionModal,
  setShowLoginModal,
  setShowRegisterModal,
} = uiSlice.actions;

export default uiSlice.reducer;
