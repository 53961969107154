import { TabularData } from "../../interfaces/application/tabularData";
import "./DataTable.css";

const jsonToTsx = (tableData: TabularData) => {
  const numColumns: number = Object.keys(
    tableData.sections[0].section_data[0],
  ).length;
  const { object_name, ...propertiesObject } = Object(
    tableData.sections[0].section_data[0],
  );
  const properties: string[] = Object.keys(propertiesObject);
  return (
    <table>
      {/* Table Header */}
      <thead>
        <tr>
          <th>&nbsp;</th>
          {properties.map((p) => {
            return <th key={p}>{p}</th>;
          })}
        </tr>
      </thead>
      {/* Sections */}
      <tbody>
        {tableData.sections.map((s) => {
          return (
            <>
              {/* Section Header */}
              <tr>
                <th className="table-section" colSpan={numColumns}>
                  {s.section_title}
                </th>
              </tr>
              {/* Section Data */}
              {Object.values(s.section_data).map((sd) => {
                const { object_name, ...objectValues } = sd;
                const values: (number | string)[] = Object.values(objectValues);
                return (
                  <tr>
                    {/* Object Name */}
                    <th>{object_name}</th>
                    {/* Object property values */}
                    {values.map((v) => {
                      return <td>{v}</td>;
                    })}
                  </tr>
                );
              })}
            </>
          );
        })}
      </tbody>
    </table>
  );
};

const DataTable = ({ data: tableData }: { data: TabularData }) => {
  return jsonToTsx(tableData);
};

export default DataTable;
