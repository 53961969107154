import { createSelector } from "@reduxjs/toolkit";
import {
  getEditedTilesIds,
  getSelectedTileId,
  getTiles,
} from "./tilesSelectors";

export const getTile = (tileId: string) =>
  createSelector(getTiles, (tiles) => tiles[tileId]);

export const getTilePlotType = (tileId: string) =>
  createSelector(getTile(tileId), (tile) => tile?.plotType);

export const getTileDatasetPaths = (tileId: string) =>
  createSelector(getTile(tileId), (tile) => tile?.datasetPaths);

export const getTileTitle = (tileId: string) =>
  createSelector(getTile(tileId), (tile) => tile?.title);

export const getTileViewId = (tileId: string) =>
  createSelector(getTile(tileId), (tile) => tile?.viewId);

export const getTilePosition = (tileId: string) =>
  createSelector(getTile(tileId), (tile) => tile?.position);

export const getIsSelectedTile = (tileId: string) =>
  createSelector(
    getSelectedTileId,
    (selectedTileId) => selectedTileId === tileId,
  );

export const getTileIsEdited = (tileId: string) =>
  createSelector(getEditedTilesIds, (editedTilesIds) =>
    editedTilesIds.includes(tileId),
  );
