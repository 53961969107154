import { createSelector } from "@reduxjs/toolkit";
import { getDatasets } from "./datasetsSelectors";
import { DatasetPath } from "../interfaces/application/redux/Tile";
import { Dataset } from "../interfaces/application/redux/Dataset";

export const getDataset = (datasetPath: DatasetPath) =>
  createSelector(getDatasets, (datasets) => datasets[datasetPath.dataSetId]);

export const getDatasetTitle = (datasetPath: DatasetPath) =>
  createSelector(getDataset(datasetPath), (dataset) => dataset?.title);

export const getDatasetTitlesForPaths = (datasetPaths: DatasetPath[]) =>
  createSelector(getDatasets, (datasets) => {
    const filteredDatasets: Dataset[] = [];
    datasetPaths?.forEach((datasetPath) => {
      filteredDatasets.push(
        Object.values(datasets).filter((dataset) => {
          return (
            dataset.id === datasetPath.dataSetId &&
            dataset.dataSourceId === datasetPath.dataSourceId
          );
        })[0],
      );
    });
    const titles = filteredDatasets?.map((dataset) => dataset.title);
    return titles;
  });
