import { configureStore } from "@reduxjs/toolkit";
import tilesSlice from "./slices/tilesSlice";
import uiSlice from "./slices/uiSlice";
import viewsSlice from "./slices/viewsSlice";
import datasetsSlice from "./slices/datasetsSlice";
import accountSlice from "./slices/accountSlice";

export const store = configureStore({
  reducer: {
    tiles: tilesSlice,
    ui: uiSlice,
    views: viewsSlice,
    datasets: datasetsSlice,
    account: accountSlice,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type TypedDispatch = typeof store.dispatch;
