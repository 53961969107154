import { BiX } from "react-icons/bi";
import { useTypedDispatch, useTypedSelector } from "../../hooks";
import { getRootNodes } from "../../selectors/datasetsSelectors";
import { setIsSelectingDataSet } from "../../slices/uiSlice";
import TreeStructure from "../TreeStructure/TreeStructure";
import "./DatasetSelection.css";
import { useState } from "react";
import { DropdownMenu } from "../DropdownMenu/DropdownMenu";
import { availableDataSources } from "../../constants";
import { addRootNode } from "../../slices/datasetsSlice";
import { fetchTreeStructure } from "../../apiCalls";
import { DatasetPath } from "../../interfaces/application/redux/Tile";
import { DtoTreeNode } from "../../interfaces/application/dto/dtoTreeNode";

const DatasetSelection = ({
  allSelectedDatasets,
  onDatasetToggle,
}: {
  allSelectedDatasets: DatasetPath[];
  onDatasetToggle: (dataset: DatasetPath) => void;
}) => {
  const [selectedDataSourceId, setSelectedDataSourceId] = useState("");
  const rootNodes = useTypedSelector(getRootNodes);
  const rootNode: DtoTreeNode =
    useTypedSelector(getRootNodes)[selectedDataSourceId];

  const selectedDatasetsForDatasource = allSelectedDatasets.filter(
    (dataset) => {
      return dataset.dataSourceId === selectedDataSourceId;
    },
  );

  const dispatch = useTypedDispatch();

  const onDataSourceDropDownItemClick = async (dataSourceId: string) => {
    if (!Object.keys(rootNodes).includes(dataSourceId)) {
      const rootNode: DtoTreeNode = await fetchTreeStructure({
        dataSourceId: dataSourceId,
      });
      dispatch(
        addRootNode({
          dataSourceId: dataSourceId,
          rootNode: rootNode,
        }),
      );
    }
    setSelectedDataSourceId(dataSourceId);
    dispatch(setIsSelectingDataSet(true));
  };

  return (
    <>
      <div className="dataset-selection-header">
        <DropdownMenu
          onDropdownItemClick={(dataSourceId) =>
            onDataSourceDropDownItemClick(dataSourceId)
          }
          items={Object.values(availableDataSources)}
          title={
            selectedDataSourceId ? selectedDataSourceId : "Select Data Source"
          }
          error={""}
        />
        <div>
          <BiX
            className="dataset-selection-cancel-symbol"
            title="Cancel"
            onClick={() => dispatch(setIsSelectingDataSet(false))}
          />
        </div>
      </div>
      {rootNode ? (
        <TreeStructure
          rootNode={rootNode}
          onDatasetToggle={onDatasetToggle}
          selectedDataSourceId={selectedDataSourceId}
          selectedDatasets={selectedDatasetsForDatasource}
        />
      ) : (
        "No Data Source selected!"
      )}
    </>
  );
};

export default DatasetSelection;
