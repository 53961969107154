import { useTypedDispatch, useTypedSelector } from "../../hooks";
import "./SelectedViewInfo.css";
import {
  addTiles,
  removeTile,
  removeEditedTileIds,
  resetModifiedTileIds,
  replaceTiles,
  updateTilesCopy,
} from "../../slices/tilesSlice";
import { toggleIsAddingTile } from "../../slices/uiSlice";
import {
  setSelectedViewEdited,
  setSelectedViewId,
  addView,
  removeView,
} from "../../slices/viewsSlice";
import {
  getOpenViews,
  getSelectedViewEdited,
  getSelectedViewId,
  getViews,
} from "../../selectors/viewsSelectors";
import {
  getDisplayTilesIdsForSelectedView,
  getTilesForView,
} from "../../selectors/tilesSelectors";

import {
  deleteView_,
  fetchTilesForView,
  createView,
  updateTilesForView,
} from "../../apiCalls";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  BiPlus,
  BiSolidTrash,
  BiSolidSave,
  BiSave,
  BiRewind,
} from "react-icons/bi";
import popToastWithMessage from "../../utils/toast";
import { toastTypes } from "../../constants";
import { toDtoTile } from "../../interfaces/application/redux/Tile";
import { DtoTile } from "../../interfaces/application/dto/dtoTile";
import { getAccountInfo } from "../../selectors/accountSelectors";

const SelectedViewInfo = ({ isAddingTile }: { isAddingTile: boolean }) => {
  const accountInfo = useTypedSelector(getAccountInfo);
  const selectedViewId = useTypedSelector(getSelectedViewId);
  const selectedViewEdited = useTypedSelector(getSelectedViewEdited);
  const views = useTypedSelector(getViews);
  const openViews = useTypedSelector(getOpenViews);

  // This needs to be reconsidered if tiles in state become larger objects!
  const selectedViewTiles = useTypedSelector(getTilesForView(selectedViewId));
  const selectedViewDisplayTilesIds = useTypedSelector(
    getDisplayTilesIdsForSelectedView,
  );

  const [viewSaveAsName, setViewSaveAsName] = useState("");
  const [viewSaveAsError, setViewSaveAsError] = useState("");
  const [isSavingViewAs, setIsSavingViewAs] = useState(false);
  const [isDeletingView, setIsDeletingView] = useState(false);

  const dispatch = useTypedDispatch();

  useEffect(() => {
    resetSaveViewAsState();
    setIsDeletingView(false);
  }, [selectedViewId]);

  const onDiscardAllChangesButtonClick = async () => {
    if (selectedViewEdited) {
      try {
        dispatch(setSelectedViewEdited(false));
        dispatch(resetModifiedTileIds());
        const tiles = await fetchTilesForView(selectedViewId);
        dispatch(replaceTiles(tiles));
        popToastWithMessage(
          `Successfully discarded changes in view "${views[selectedViewId].title}".`,
        );
      } catch (error) {
        popToastWithMessage(`Error fetching tiles`, toastTypes.ERROR);
        return;
      }
    }
  };

  const onSaveChangesButtonClick = async () => {
    if (!selectedViewEdited) {
      return;
    }
    // Get all current tileDtos for view to save.
    const updateTileDtos = selectedViewDisplayTilesIds.map((id) => {
      return toDtoTile(selectedViewTiles[id], id, selectedViewId);
    });

    // Recalculate their positions.
    if (updateTileDtos.length > 0) {
      updateTileDtos.sort((a, b) => a.position - b.position);

      for (let i = 0; i < updateTileDtos.length; i++) {
        updateTileDtos[i].position = i;
      }
    }

    // Patch view with these tileDtos.
    const updateViewResponse = await updateTilesForView(
      selectedViewId,
      updateTileDtos,
    );

    if (updateViewResponse.error) {
      popToastWithMessage(`Error updating view`, toastTypes.ERROR);
      return;
    }

    // Get current tiles for view and remove them from state.
    Object.keys(selectedViewTiles).map((tileId) => {
      dispatch(removeTile(tileId));
      dispatch(removeEditedTileIds([tileId]));
    });

    // Add tiles from response to state.
    dispatch(addTiles(updateViewResponse.updatedTiles));
    dispatch(setSelectedViewEdited(false));
    dispatch(updateTilesCopy());
    popToastWithMessage(
      `Successfully saved view "${views[selectedViewId].title}".`,
    );
  };

  const onAddTileButtonClick = () => {
    dispatch(toggleIsAddingTile());
  };

  // DELETE view

  const onDeleteViewButtonClick = () => {
    setIsDeletingView(true);
    setIsSavingViewAs(false);
  };

  const onDeleteViewConfirm = async () => {
    const deleteViewResult = await deleteView_(selectedViewId);
    if (deleteViewResult.error) {
      popToastWithMessage(`Error deleting view`, toastTypes.ERROR);
      return;
    }
    popToastWithMessage(
      `Successfully deleted view "${views[selectedViewId].title}".`,
    );

    dispatch(removeView(selectedViewId));

    // change selected tab to nearest tab if any
    const indexOfView = openViews.indexOf(views[selectedViewId]);
    if (openViews[indexOfView + 1]) {
      dispatch(setSelectedViewId(openViews[indexOfView + 1].id));
    } else if (openViews[indexOfView - 1]) {
      dispatch(setSelectedViewId(openViews[indexOfView - 1].id));
    } else {
      dispatch(setSelectedViewId(""));
    }
    dispatch(setSelectedViewEdited(false));
    dispatch(resetModifiedTileIds());
  };

  // SAVE VIEW AS
  const onSaveViewAsButtonClick = () => {
    setIsSavingViewAs(true);
    setIsDeletingView(false);
  };

  const resetSaveViewAsState = () => {
    setViewSaveAsName("");
    setViewSaveAsError("");
    setIsSavingViewAs(false);
  };

  const onSaveViewAsConfirm = async () => {
    if (!viewSaveAsName) {
      popToastWithMessage("View name can't be empty.", toastTypes.ERROR);
    } else {
      // Generate new id for each tile.
      const dtoTiles: DtoTile[] = [];
      selectedViewDisplayTilesIds.forEach((id) => {
        dtoTiles.push(
          toDtoTile(selectedViewTiles[id], uuidv4(), selectedViewId),
        );
      });

      const postViewResult = await createView(
        viewSaveAsName,
        dtoTiles,
        accountInfo.id,
      );
      if (postViewResult.error) {
        popToastWithMessage(
          `There was a problem creating view "${viewSaveAsName}".`,
          toastTypes.ERROR,
        );
        return;
      }
      const createdView = postViewResult.addViewResult;
      dispatch(addView(createdView));

      resetSaveViewAsState();
      popToastWithMessage(`Successfully created view "${createdView.title}".`);
    }
  };

  return (
    <>
      {selectedViewId ? (
        <div className="selected-view-info">
          Selected View
          {selectedViewEdited ? " (edited)" : ""}: "
          {views[selectedViewId].title}"
          <div>
            <BiRewind
              className={
                "menu-symbol " + (selectedViewEdited ? "" : "greyed-out")
              }
              onClick={onDiscardAllChangesButtonClick}
              title="Discard Changes"
            />
            <BiSolidSave
              className={
                "menu-symbol " + (selectedViewEdited ? "" : "greyed-out")
              }
              onClick={onSaveChangesButtonClick}
              title="Save Changes"
            />
            {!isAddingTile && (
              <BiPlus
                className={"menu-symbol "}
                onClick={onAddTileButtonClick}
                title="Add Tile"
              />
            )}
            <BiSave
              className={"menu-symbol "}
              onClick={onSaveViewAsButtonClick}
              title="Save As..."
            />
            <BiSolidTrash
              className={"menu-symbol "}
              onClick={onDeleteViewButtonClick}
              title="Delete View"
            />
            {isSavingViewAs && (
              <>
                <p>
                  This will create a new view and leave the current view
                  unchanged.
                </p>
                <div>
                  <input
                    style={{ margin: "4px" }}
                    type="text"
                    placeholder={"Enter new view name"}
                    onChange={(e) => setViewSaveAsName(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="save-view-as-item"
                    onClick={onSaveViewAsConfirm}
                  >
                    CONFIRM
                  </button>
                  <button
                    className="save-view-as-item"
                    onClick={resetSaveViewAsState}
                  >
                    CANCEL
                  </button>
                </div>
                <p>{viewSaveAsError ?? ""}</p>
              </>
            )}
            {isDeletingView && (
              <>
                <p>
                  Do you really want to delete view "
                  {views[selectedViewId].title}" ?
                </p>
                <button
                  className="delete-view-item"
                  onClick={onDeleteViewConfirm}
                >
                  DELETE VIEW
                </button>
                <button
                  className="delete-view-item"
                  onClick={() => setIsDeletingView(false)}
                >
                  CANCEL
                </button>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="selected-view-info">No view selected.</div>
      )}
    </>
  );
};

export default SelectedViewInfo;
