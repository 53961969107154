import { Dropdown, DropdownButton } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // needed for dropdown style

interface DropdownMenuProps {
  onDropdownItemClick: (item: string) => void;
  items: string[];
  title: string;
  error?: string;
}

export const DropdownMenu = ({
  onDropdownItemClick,
  items,
  title,
  error,
}: DropdownMenuProps) => {
  return (
    <>
      <DropdownButton
        id="dropdown-button-drop-1"
        title={title}
        size="sm"
        variant="secondary"
      >
        {items.map((item) => (
          <Dropdown.Item key={item} onClick={() => onDropdownItemClick(item)}>
            {item}
          </Dropdown.Item>
        ))}
      </DropdownButton>
      <p>{error ? error : ""}</p>
    </>
  );
};
