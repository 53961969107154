// import { ChartJSContent } from "../../external/chartjs";
// import { VirSatMassObjects } from "../../external/virsat";
// import { TabularData } from "../tabularData";
import { DtoDataset } from "../dto/dtoDataset";

export interface Dataset {
  id: string;
  title: string;
  type: string;
  content: object; //ChartJSContent | VirSatMassObjects | TabularData;
  dataSourceId: string;
}

export interface Datasets {
  [id: string]: Dataset;
}

export const fromDtoDataset = (dtoDataset: DtoDataset): Dataset => {
  return {
    id: dtoDataset.id,
    title: dtoDataset.title,
    type: dtoDataset.type,
    content: dtoDataset.content,
    dataSourceId: dtoDataset.dataSourceId,
  };
};
