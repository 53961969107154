import { RootState } from "../store";

export const getIsEditingTile = (state: RootState) => state.ui.isEditingTile;
export const getIsAddingTile = (state: RootState) => state.ui.isAddingTile;
export const getIsCreatingView = (state: RootState) => state.ui.isCreatingView;
export const getIsSideBarVisible = (state: RootState) =>
  state.ui.isSideBarVisible;
export const getIsLoadingUserData = (state: RootState) =>
  state.ui.isLoadingUserData;
export const getIsSelectingDataSet = (state: RootState) =>
  state.ui.isSelectingDataSet;
export const getShowViewSelectionModal = (state: RootState) =>
  state.ui.showViewSelectionModal;
export const getShowLoginModal = (state: RootState) => state.ui.showLoginModal;
export const getShowRegisterModal = (state: RootState) =>
  state.ui.showRegisterModal;
